import { postQueryOptions } from "@/api/posts.api";
import { RichEditor } from "@/components/pages/pages-form";
import { PostsForm } from "@/components/posts/posts-form";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { isEmpty } from "lodash";
import { z } from "zod";

const routeParamsSchema = z.object({
  postId: z.string(),
});

function EditPost() {
  const postId = Route.useParams().postId;
  const postQuery = useSuspenseQuery(postQueryOptions(postId));
  const post = postQuery.data;

  if (!isEmpty(post.tiptapJson)) {
    return <RichEditor post={post} />;
  }

  return <PostsForm initialValues={post} isEdit />;
}

export const Route = createFileRoute("/_authenticated/posts/$postId")({
  component: () => <EditPost />,
  parseParams: (params) => routeParamsSchema.parse(params),
  loader: ({ context: { queryClient }, params }) =>
    queryClient.ensureQueryData(postQueryOptions(params.postId)),
});
