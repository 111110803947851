import { Link, useMatchRoute } from "@tanstack/react-router";

import { cn } from "@/lib/utils";

export function MainNav({
  className,
  ...props
}: React.HTMLAttributes<HTMLElement>) {
  const matchRoute = useMatchRoute();

  // const dash = matchRoute({ to: "/dashboard" });
  // @ts-expect-error - TODO: fix this
  const events = matchRoute({ to: "/events", fuzzy: true });
  const posts = matchRoute({ to: "/posts", fuzzy: true });
  const stories = matchRoute({ to: "/stories", fuzzy: true });
  // const profiles = matchRoute({ to: "/profiles", fuzzy: true });
  const residents = matchRoute({ to: "/residents", fuzzy: true });
  // @ts-expect-error - TODO: fix this
  const links = matchRoute({ to: "/links", fuzzy: true });
  const incidents = matchRoute({ to: "/incidents", fuzzy: true });
  const feed = matchRoute({ to: "/feed", fuzzy: true });

  return (
    <nav
      className={cn("flex items-center space-x-4 lg:space-x-6", className)}
      {...props}
    >
      {/* <Link
        to="/dashboard"
        className={cn(
          "text-sm font-medium transition-colors hover:text-primary",
          !dash && "text-muted-foreground",
        )}
      >
        Dashboard
      </Link> */}
      <Link
        // @ts-expect-error - TODO: fix this
        to="/feed/stories"
        className={cn(
          "text-sm font-medium transition-colors hover:text-primary",
          !feed && "text-muted-foreground",
        )}
      >
        Feed
      </Link>

      <Link
        to="/posts"
        className={cn(
          "text-sm font-medium transition-colors hover:text-primary",
          !posts && "text-muted-foreground",
        )}
      >
        Posts
      </Link>
      <Link
        to="/stories"
        className={cn(
          "text-sm font-medium transition-colors hover:text-primary",
          !stories && "text-muted-foreground",
        )}
      >
        Stories
      </Link>
      <Link
        // @ts-expect-error - TODO: fix this
        to="/events"
        className={cn(
          "text-sm font-medium transition-colors hover:text-primary",
          !events && "text-muted-foreground",
        )}
      >
        Events
      </Link>
      <Link
        // @ts-expect-error - TODO: fix this
        to="/links"
        className={cn(
          "text-sm font-medium transition-colors hover:text-primary",
          !links && "text-muted-foreground",
        )}
      >
        Quick Links
      </Link>
      {/* <Link
        to="/profiles"
        className={cn(
          "text-sm font-medium transition-colors hover:text-primary",
          !profiles && "text-muted-foreground",
        )}
      >
        Profiles
      </Link> */}
      <Link
        to="/residents"
        className={cn(
          "text-sm font-medium transition-colors hover:text-primary",
          !residents && "text-muted-foreground",
        )}
      >
        Residents
      </Link>
      <Link
        to="/incidents"
        className={cn(
          "text-sm font-medium transition-colors hover:text-primary",
          !incidents && "text-muted-foreground",
        )}
      >
        Incidents
      </Link>
    </nav>
  );
}
