import { create } from "zustand";

export interface ConversationsState {
  participantId: string;
  participantType: "phone" | "user" | "organization";
  setParticipantId: (participantId: string) => void;
  setParticipantType: (
    participantType: "phone" | "user" | "organization",
  ) => void;
}

export const useConversations = create<ConversationsState>((set) => ({
  participantId: "",
  participantType: "phone",
  setParticipantId: (participantId) => set({ participantId }),
  setParticipantType: (participantType) => set({ participantType }),
}));
