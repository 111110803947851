import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { toast } from "sonner";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { ChevronLeft } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { createMedia, updateMedia } from "@/api/media.api";
import { mediaZod } from "zora-api/concerns/media/media.zod";
import { Icons } from "../ui/icons";

type MediaFormData = z.infer<typeof mediaZod>;

type MediaFormProps =
  | {
      isEdit: true;
      initialValues: MediaFormData & { id: string };
    }
  | {
      isEdit: false;
      initialValues?: Partial<MediaFormData>;
    };

export function MediaForm({ isEdit, initialValues }: MediaFormProps) {
  const router = useRouter();
  const navigate = useNavigate();
  const mediaId = isEdit ? initialValues.id : undefined;
  const queryClient = useQueryClient();

  const form = useForm<MediaFormData>({
    resolver: zodResolver(mediaZod),
    defaultValues: {
      title: initialValues?.title || "",
      description: initialValues?.description || "",
      cloudStorageObjectId: initialValues?.cloudStorageObjectId || "",
    },
  });

  const { mutate: createMediaMutation, isPending: isCreatingMedia } =
    useMutation({
      mutationFn: (media: MediaFormData) => createMedia(media),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["media"] });
        navigate({ to: "/media" });
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const { mutate: updateMediaMutation, isPending: isUpdatingMedia } =
    useMutation({
      mutationFn: (media: MediaFormData & { id: string }) => updateMedia(media),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["media"] });
        if (mediaId)
          queryClient.invalidateQueries({
            queryKey: ["media", mediaId],
          });
        navigate({ to: "/media" });
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const isSubmitting = isCreatingMedia || isUpdatingMedia;

  const onSubmit = (data: MediaFormData) => {
    if (isEdit && mediaId) {
      updateMediaMutation({
        ...data,
        id: mediaId,
      });
    } else {
      createMediaMutation(data);
    }
  };

  return (
    <div className="mx-auto grid max-w-[59rem] flex-1 auto-rows-max gap-4 mt-4">
      <Form {...form}>
        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={() => {
              router.history.back();
            }}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Back</span>
          </Button>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            {isEdit ? "Edit Media" : "New Media"}
          </h1>
          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => {
                router.history.back();
              }}
            >
              Discard
            </Button>
            <Button
              size="sm"
              onClick={form.handleSubmit(onSubmit)}
              disabled={isSubmitting}
            >
              {isSubmitting ? <Icons.spinner className="h-4 w-4 mr-2" /> : null}
              Save Media
            </Button>
          </div>
        </div>

        <Card>
          <CardHeader>
            <CardTitle>Media Details</CardTitle>
            <CardDescription>Enter the details for your media.</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="grid gap-6">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Title</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="cloudStorageObjectId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Cloud Storage Object ID</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </CardContent>
        </Card>

        <div className="flex items-center justify-center gap-2 md:hidden mt-4">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              router.history.back();
            }}
          >
            Discard
          </Button>
          <Button
            size="sm"
            onClick={form.handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            {isSubmitting ? <Icons.spinner className="h-4 w-4 mr-2" /> : null}
            Save Media
          </Button>
        </div>
      </Form>
    </div>
  );
}
