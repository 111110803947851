import CategoriesTable from "@/components/categories/categories-table";
import { TableHeader } from "@/components/categories/table-header";
import { createFileRoute } from "@tanstack/react-router";

function Categories() {
  return (
    <div>
      <TableHeader />
      <CategoriesTable />
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/app/feed/")({
  component: Categories,
});
