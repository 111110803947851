import { orgPhoneNumberQueryOptions } from "@/api/conversations.api";
import { Conversations } from "@/components/conversations/conversations";
import { orgKey } from "@/hooks/useAuth";
import { createFileRoute } from "@tanstack/react-router";

export function ConversationsRoute() {
  return <Conversations />;
}

export const Route = createFileRoute("/_authenticated/conversations/")({
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(
      orgPhoneNumberQueryOptions(localStorage.getItem(orgKey) || ""),
    ),
  component: ConversationsRoute,
});
