import { cn } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { PlusCircle } from "lucide-react";
import { buttonVariants } from "../ui/button-shared";

export function TableHeader() {
  return (
    <div className="flex justify-end mb-2">
      <Link
        className={cn(buttonVariants({ size: "sm", className: "h-7 gap-1" }))}
        to="/web-links/new"
      >
        <PlusCircle className="h-3.5 w-3.5" />
        <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
          Add Web Link
        </span>
      </Link>
    </div>
  );
}
