import { CategoryForm } from "@/components/categories/category-form";
import { createFileRoute } from "@tanstack/react-router";

function NewQuickAccess() {
  return <CategoryForm isEdit={false} />;
}

export const Route = createFileRoute("/_authenticated/app/quick-access/new")({
  component: NewQuickAccess,
});
