import { queryOptions } from "@tanstack/react-query";
import { axios, buildHeaders } from "./axios";
import { Events } from "zora-api/concerns/events/events.router";
import { EventInput } from "zora-api/concerns/events/events.zod";

export const eventsQueryOptions = queryOptions({
  queryKey: ["events"],
  queryFn: () => fetchEvents(),
});

export const fetchEvents = async () => {
  return axios
    .get<Events>("/events", {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const eventQueryOptions = (id: string) =>
  queryOptions({
    queryKey: ["event", id],
    queryFn: () => fetchEvent(id),
  });

export const fetchEvent = async (id: string) => {
  return axios
    .get<Events[0]>(`/events/${id}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const createEvent = async (data: EventInput) => {
  return axios
    .post<Events[0]>("/events", data, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const editEvent = async (data: EventInput & { id: string }) => {
  return axios
    .put<Events[0]>(`/events/${data.id}`, data, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const deleteEvent = async (id: string) => {
  return axios
    .delete<Events[0]>(`/events/${id}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};
