import {
  QuickAccessSections,
  QuickAccessSection,
} from "zora-api/concerns/quick-access/quick-access.router";
import {
  deleteQuickAccessSection,
  fetchQuickAccessList,
  reorderQuickAccessSection,
} from "@/api/quick-access.api";
import GridIcon from "@/components/icons/grid-icon";
import HorizontalMoveIcon from "@/components/icons/horizontal-move-icon";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import debounce from "lodash/debounce";
import { MoreHorizontal } from "lucide-react";
import { useEffect, useState } from "react";
import { ConfirmModal } from "../ui/confirm-modal";

function SortableRow({
  section,
  navigate,
  setDeleteSectionId,
}: {
  section: QuickAccessSection;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigate: any;
  setDeleteSectionId: (id: string | null) => void;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: section.id });

  const style = {
    transform: transform ? `translate3d(0px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  return (
    <TableRow ref={setNodeRef} style={style}>
      <TableCell
        className="hidden sm:table-cell cursor-move"
        {...attributes}
        {...listeners}
      >
        <HorizontalMoveIcon className="handle h-4 w-4" />
      </TableCell>
      <TableCell className="font-medium">{section.title}</TableCell>
      <TableCell>
        <Badge variant="outline" className="p-2">
          {section.displayType === "grid" && <GridIcon className="h-4 w-4" />}
          {/* {section.displayType === "list" && <ListIcon className="h-4 w-4" />}
          {section.displayType === "carousel" && (
            <CarouselIcon className="h-4 w-4" />
          )} */}
          <span className="ml-1">{section.displayType}</span>
        </Badge>
      </TableCell>
      <TableCell className="hidden md:table-cell">
        {section.postables.length}
      </TableCell>
      <TableCell className="hidden md:table-cell">
        <Badge variant={"outline"}>{section.order}</Badge>
      </TableCell>
      <TableCell>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => {
                navigate({
                  to: `/app/quick-access/sections/${section.id}`,
                });
              }}
            >
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setDeleteSectionId(section.id)}>
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  );
}

export default function SectionsTable() {
  const [deleteSectionId, setDeleteSectionId] = useState<string | null>(null);
  const [sections, setSections] = useState<QuickAccessSections>([]);
  const { data } = useQuery({
    queryKey: ["quick-access-sections"],
    queryFn: () => {
      return fetchQuickAccessList();
    },
  });
  const queryClient = useQueryClient();

  const { mutate: deleteSectionMutation, isPending: isLoading } = useMutation({
    mutationFn: deleteQuickAccessSection,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["quick-access-sections"] });
      setDeleteSectionId(null);
    },
  });

  const { mutateAsync } = useMutation({
    mutationFn: reorderQuickAccessSection,
  });

  useEffect(() => {
    if (data) {
      setSections(data);
    }
  }, [data]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const updateSectionsOrderApi = (updatedSections: QuickAccessSections) => {
    mutateAsync({ sections: updatedSections }).catch((e) => {
      console.error(e);
    });
  };

  const navigate = useNavigate();
  const debouncedUpdateOrder = debounce(updateSectionsOrderApi, 2500);

  const handleDragEnd = (event: {
    active: { id: number | string };
    over: { id: number | string } | null;
  }) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setSections((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);

        const updatedItems = newItems.map((item, index) => ({
          ...item,
          order: index + 1,
        }));

        debouncedUpdateOrder(updatedItems);

        return updatedItems;
      });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Quick Access Sections</CardTitle>
        <CardDescription>
          Sections allow you to organize your feed into different quick access
          areas.
        </CardDescription>
      </CardHeader>
      <ConfirmModal
        open={Boolean(deleteSectionId)}
        onConfirm={() => {
          if (deleteSectionId) deleteSectionMutation(deleteSectionId);
        }}
        onDismiss={() => {
          setDeleteSectionId(null);
        }}
        isLoading={isLoading}
      />
      <CardContent>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={sections}
            strategy={verticalListSortingStrategy}
          >
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="hidden w-[100px] sm:table-cell">
                    <span className="sr-only">Image</span>
                  </TableHead>
                  <TableHead>Title</TableHead>
                  <TableHead>Display Type</TableHead>

                  <TableHead className="hidden md:table-cell">Items</TableHead>
                  <TableHead className="hidden md:table-cell">Order</TableHead>
                  <TableHead>
                    <span className="sr-only">Actions</span>
                  </TableHead>
                </TableRow>
              </TableHeader>

              <TableBody>
                {sections.map((section) => (
                  <SortableRow
                    key={section.id}
                    section={section}
                    navigate={navigate}
                    setDeleteSectionId={setDeleteSectionId}
                  />
                ))}
              </TableBody>
            </Table>
          </SortableContext>
        </DndContext>
      </CardContent>
      <CardFooter>
        <div className="text-xs text-muted-foreground">
          Showing <strong>1-{sections.length}</strong> of{" "}
          <strong>{sections.length}</strong> sections
        </div>
      </CardFooter>
    </Card>
  );
}
