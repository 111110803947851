import { webLinkQueryOptions } from "@/api/weblinks.api";
import { WebLinksForm } from "@/components/web-links/web-links-form";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const routeParamsSchema = z.object({
  webLinkId: z.string(),
});

function EditWebLink() {
  const webLinkId = Route.useParams().webLinkId;
  const webLinkQuery = useSuspenseQuery(webLinkQueryOptions(webLinkId));
  const webLink = webLinkQuery.data;

  return <WebLinksForm initialValues={webLink} isEdit />;
}
export const Route = createFileRoute("/_authenticated/web-links/$webLinkId")({
  component: () => <EditWebLink />,
  parseParams: (params) => routeParamsSchema.parse(params),
  loader: ({ context: { queryClient }, params }) =>
    queryClient.ensureQueryData(webLinkQueryOptions(params.webLinkId)),
});
