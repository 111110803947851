import ReactCrop, { type Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { presignedUrl, uploadToPresignedUrl } from "@/api/storage.api";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useMutation } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

function Library() {
  const [crop, setCrop] = useState<Crop>();
  const { mutate: presignedUrlMutation, data: presignedUrlData } = useMutation({
    mutationFn: presignedUrl,
  });

  const form = useForm<{ file: File }>();

  useEffect(() => {
    presignedUrlMutation({
      contentType: "image/png",
      extension: "png",
      persist: true,
    });
  }, [presignedUrlMutation]);

  const onSubmit = (data: { file: File }) => {
    if (presignedUrlData?.presigned) {
      uploadToPresignedUrl(presignedUrlData.presigned, data.file);
    }
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="grid gap-4 py-4">
                <FormField
                  control={form.control}
                  name="file"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Picture</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="File"
                          {...{ ...field, value: undefined }}
                          type="file"
                        />
                      </FormControl>
                      <FormDescription>
                        {presignedUrlData?.presigned
                          ? "File uploaded"
                          : "Upload a file"}
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <Button type="submit">Submit</Button>
            </form>
          </Form>
        </div>

        <div>
          <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
            <img src={"https://storage.googleapis.com/csmz-p/dog.png"} />
          </ReactCrop>
        </div>
      </div>
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/library")({
  component: Library,
});
