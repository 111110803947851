import { CreateStoryInput } from "zora-api/concerns/stories/stories.zod";
import { create } from "zustand";

export interface StoriesModalState {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  defaultValues: Partial<CreateStoryInput>;
  setDefaultValues: (values: Partial<CreateStoryInput>) => void;
  storyId: string | null;
  setStoryId: (id: string | null) => void;
}

export const useStoriesModal = create<StoriesModalState>((set) => ({
  isOpen: false,
  setIsModalOpen: (isOpen: boolean) => set(() => ({ isOpen })),
  defaultValues: {},
  setDefaultValues: (values: Partial<CreateStoryInput>) =>
    set(() => ({ defaultValues: values })),
  storyId: null,
  setStoryId: (id: string | null) => set(() => ({ storyId: id })),
}));
