export const colorPalette = [
  { hex: "#ffffff", name: "White" },
  { hex: "#edf7fa", name: "Baby Blue" },
  { hex: "#edf7f4", name: "Lavender Mist" },
  { hex: "#edf7ef", name: "Mauve Chalk" },
  { hex: "#f2edf7", name: "Pale Purple" },
  { hex: "#f7edf7", name: "Pink Powder" },
  { hex: "#f7edf2", name: "Rose Cloud" },
  { hex: "#f7edee", name: "Salmon Whisper" },
  { hex: "#f7f0ed", name: "Peach Puff" },
  { hex: "#f7f4ed", name: "Sunset Ivory" },
  { hex: "#f7f9ed", name: "Daffodil Silk" },
  { hex: "#f4f7ed", name: "Mint Cream" },
  { hex: "#eff7ed", name: "Honeydew Dew" },
  { hex: "#edf7ee", name: "Seafoam Breeze" },
  { hex: "#edf7f2", name: "Turquoise Bliss" },
  { hex: "#edf7f7", name: "Cyan Light" },
  { hex: "#edf2f7", name: "Periwinkle Dust" },
  { hex: "#edeef7", name: "Lilac Haze" },
  { hex: "#f0edf7", name: "Wisteria Dream" },
  { hex: "#f4edf7", name: "Violet Veil" },
  { hex: "#f7edfa", name: "Orchid Mist" },
  { hex: "#f0e6db", name: "Almond Silk" },
  { hex: "#e8e3d9", name: "Taupe Whisper" },
  { hex: "#e0dcd5", name: "Cool Gray" },
  { hex: "#d9d6d0", name: "Silver Sleet" },
  { hex: "#cfcfcf", name: "Foggy Gray" },
  { hex: "#b8b8b8", name: "Steel Frost" },
  { hex: "#a3a3a3", name: "Slate Shadow" },
  { hex: "#d1c4b1", name: "Khaki Haze" },
  { hex: "#b0a597", name: "Cedar Brown" },
  { hex: "#9d9282", name: "Umber Dust" },
];
