/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  fetchConversations,
  orgPhoneNumberQueryOptions,
} from "@/api/conversations.api";
import { Chat } from "@/components/messenger/chat";
import { Sidebar } from "@/components/messenger/sidebar";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { orgKey, useAuth } from "@/hooks/useAuth";
import { cn } from "@/lib/utils";
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import { Route, useParams } from "@tanstack/react-router";
import React, { useEffect, useState } from "react";
import { useConversations } from "./use-conversations";

const defaultLayout = [300, 480];
const defaultCollapsed = false;
const navCollapsedSize = 100;

export function Conversations({
  initialSelectedConversationId,
}: {
  initialSelectedConversationId?: string;
}) {
  const setParticipantId = useConversations((state) => state.setParticipantId);
  const setParticipantType = useConversations(
    (state) => state.setParticipantType,
  );
  const { data: orgPhoneNumber } = useSuspenseQuery(
    orgPhoneNumberQueryOptions(localStorage.getItem(orgKey) || ""),
  );

  const { conversationId } = useParams({ strict: false });
  const [selectedConversationId, setSelectedConversationId] = useState(
    conversationId || initialSelectedConversationId,
  );

  useEffect(() => {
    setSelectedConversationId(conversationId);
  }, [conversationId]);

  const [isCollapsed, setIsCollapsed] = React.useState(defaultCollapsed);
  const [isMobile, setIsMobile] = useState(false);
  const claims = useAuth((state) => state.claims);
  const participantType = "phone";
  const participantId = orgPhoneNumber || "fml";

  setParticipantId(participantId);
  setParticipantType(participantType);

  const { data, isLoading, error } = useQuery({
    queryKey: ["conversations", participantId],
    queryFn: () =>
      fetchConversations({
        participantId,
        participantType,
      }),
  });

  const conversations = data;

  if (!selectedConversationId && conversations?.length) {
    setSelectedConversationId(conversations[0].id);
  }

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenWidth();
    window.addEventListener("resize", checkScreenWidth);

    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  return (
    <div className="h-[calc(100vh-80px)]">
      <ResizablePanelGroup
        direction="horizontal"
        onLayout={(sizes: number[]) => {
          document.cookie = `react-resizable-panels:layout=${JSON.stringify(
            sizes,
          )}`;
        }}
        className="h-full items-stretch"
      >
        <ResizablePanel
          defaultSize={defaultLayout[0]}
          collapsedSize={navCollapsedSize}
          collapsible={true}
          minSize={isMobile ? 0 : 24}
          maxSize={isMobile ? 8 : 30}
          onCollapse={() => {
            setIsCollapsed(true);
            document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
              true,
            )}`;
          }}
          onExpand={() => {
            setIsCollapsed(false);
            document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
              false,
            )}`;
          }}
          className={cn(
            isCollapsed &&
              "min-w-[50px] md:min-w-[70px] transition-all duration-300 ease-in-out",
          )}
        >
          <Sidebar
            isCollapsed={isCollapsed || isMobile}
            participantId={participantId}
            participantType={participantType}
            chats={
              conversations?.map((conversation) => {
                const participant = conversation.participants.find(
                  (participant) =>
                    participant.participantType === "phone" &&
                    participant.participantId !== orgPhoneNumber,
                );

                const id =
                  participant?.details?.name ||
                  participant?.details?.identifier ||
                  participant?.participantId ||
                  "Unknown";

                return {
                  id: conversation.id,
                  name: id.replaceAll("+anonymous", ""),
                  messages: [],
                  variant:
                    selectedConversationId === conversation.id
                      ? "secondary"
                      : "ghost",
                };
              }) || []
            }
            isMobile={isMobile}
          />
        </ResizablePanel>
        <ResizableHandle withHandle />
        <ResizablePanel defaultSize={defaultLayout[1]} minSize={30}>
          {selectedConversationId ? (
            <Chat
              conversationId={selectedConversationId}
              orgPhoneNumber={orgPhoneNumber}
              isMobile={isMobile}
              key={selectedConversationId}
            />
          ) : null}
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
}
