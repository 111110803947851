import { MediaForm } from "@/components/media/media-form";
import { createFileRoute } from "@tanstack/react-router";

function NewMedia() {
  return <MediaForm isEdit={false} />;
}

export const Route = createFileRoute("/_authenticated/media/new")({
  component: () => <NewMedia />,
});
