import { queryOptions } from "@tanstack/react-query";
import { axios, buildHeaders } from "./axios";
import { Place } from "zora-api/prisma/generated";
import { PlaceFormData } from "zora-api/concerns/places/places.zod";

export const placesQueryOptions = queryOptions({
  queryKey: ["places"],
  queryFn: () => fetchPlaces(),
});

export const placeQueryOptions = (placeId: string) => ({
  queryKey: ["place", placeId],
  queryFn: () => fetchPlace(placeId),
});

export const fetchPlaces = async (): Promise<Place[]> => {
  return axios
    .get<Place[]>("/places", {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const fetchPlace = async (placeId: string): Promise<Place> => {
  return axios
    .get<Place>(`/places/${placeId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const updatePlace = async ({
  id: placeId,
  ...place
}: Partial<Place>): Promise<Place> => {
  return axios
    .put<Place>(`/places/${placeId}`, place, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const createPlace = async (place: PlaceFormData): Promise<Place> => {
  return axios
    .post<Place>("/places", place, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const deletePlace = async (placeId: string): Promise<void> => {
  return axios
    .delete(`/places/${placeId}`, {
      headers: buildHeaders(),
    })
    .then(() => undefined);
};

export const geocode = async (
  address: string,
): Promise<{ latitude: number; longitude: number } | null> => {
  return axios
    .post<{ latitude: number; longitude: number }>(
      `/places/geocode`,
      { address },
      {
        headers: buildHeaders(),
      },
    )
    .then((r) => r.data);
};
