import { incidentQueryOptions, updateIncident } from "@/api/incidents.api";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { isEpoch } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import {
  createFileRoute,
  useNavigate,
  useRouter,
} from "@tanstack/react-router";
import dayjs from "dayjs";
import { ChevronLeft } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const routeParamsSchema = z.object({
  incidentId: z.string(),
});

const incidentUpdateSchema = z.object({
  description: z.string(),
  resolvedAt: z.string().optional(),
  emailBody: z.string().optional(),
});

type IncidentUpdateInput = z.infer<typeof incidentUpdateSchema>;

const isEdit = true;
function EditIncident() {
  const incidentId = Route.useParams().incidentId;
  const incidentQuery = useSuspenseQuery(incidentQueryOptions(incidentId));
  const incident = incidentQuery.data;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const router = useRouter();
  const form = useForm<IncidentUpdateInput>({
    resolver: zodResolver(incidentUpdateSchema),
    defaultValues: {
      description: incident.description,
      resolvedAt:
        incident.resolvedAt && !isEpoch(incident.resolvedAt)
          ? dayjs(incident.resolvedAt).format("YYYY-MM-DDTHH:mm")
          : dayjs().format("YYYY-MM-DDTHH:mm"),
    },
  });

  const { mutate: updateIncidentMutation } = useMutation({
    mutationFn: (data: IncidentUpdateInput) =>
      updateIncident({
        id: incidentId,
        ...data,
        resolvedAt: data.resolvedAt
          ? dayjs(data.resolvedAt).toDate()
          : undefined,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["incidents"] });
      queryClient.invalidateQueries({ queryKey: ["incident", incidentId] });
      navigate({ to: "/incidents" });
    },
  });

  const onSubmit = (data: IncidentUpdateInput) => {
    updateIncidentMutation(data);
  };

  return (
    <div className="mx-auto grid max-w-[59rem] flex-1 auto-rows-max gap-4 mt-4">
      <Form {...form}>
        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={() => {
              router.history.back();
            }}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Back</span>
          </Button>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            {isEdit ? "Edit Incident" : "New Incident"}
          </h1>
          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => {
                router.history.back();
              }}
            >
              Discard
            </Button>
            <Button size="sm" onClick={form.handleSubmit(onSubmit)}>
              Save Incident
            </Button>
          </div>
        </div>

        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <Card>
              <CardHeader>
                <CardTitle>Incident</CardTitle>
                <CardDescription>
                  Create or edit an incident to be recorded in the system.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Description</FormLabel>
                        <FormControl>
                          <Textarea
                            {...field}
                            className="w-full min-h-[200px]"
                            placeholder="Describe the incident..."
                            readOnly
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="resolvedAt"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Resolved At</FormLabel>
                        <FormControl>
                          <Input type="datetime-local" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
            <Card className="overflow-hidden">
              <CardHeader>
                <CardTitle>Incident Image</CardTitle>
                <CardDescription>
                  Image from the resident's camera
                </CardDescription>
              </CardHeader>
              <CardContent>
                {incident.imageUrl && (
                  <div>
                    <img
                      src={incident.imageUrl}
                      alt="Uploaded"
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                  </div>
                )}
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Resident Communication</CardTitle>
                <CardDescription>
                  Send an email to the resident about this incident
                </CardDescription>
              </CardHeader>
              <CardContent>
                <Dialog
                  open={isEmailDialogOpen}
                  onOpenChange={setIsEmailDialogOpen}
                >
                  <DialogTrigger asChild>
                    <Button type="button" variant="outline" className="w-full">
                      Compose Email
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                      <DialogTitle>Send Email to Resident</DialogTitle>
                    </DialogHeader>
                    <FormField
                      control={form.control}
                      name="emailBody"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Email Body</FormLabel>
                          <FormControl>
                            <Textarea
                              {...field}
                              placeholder="Enter email content..."
                              className="min-h-[150px]"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <Button
                      onClick={() => {
                        // Logic to send email
                        setIsEmailDialogOpen(false);
                      }}
                      className="mt-4"
                    >
                      Send Email
                    </Button>
                  </DialogContent>
                </Dialog>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2 md:hidden">
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() => {
              router.history.back();
            }}
          >
            Discard
          </Button>
          <Button size="sm" onClick={form.handleSubmit(onSubmit)}>
            Save Incident
          </Button>
        </div>
      </Form>
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/incidents/$incidentId")({
  component: EditIncident,
  parseParams: (params) => routeParamsSchema.parse(params),
  loader: ({ context: { queryClient }, params }) =>
    queryClient.ensureQueryData(incidentQueryOptions(params.incidentId)),
});
