import { deleteWebLink, webLinksQueryOptions } from "@/api/weblinks.api";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { MoreHorizontal } from "lucide-react";
import { useState } from "react";
import { WebLink } from "zora-api/concerns/mobile/mobile.router";
import { ConfirmModal } from "../ui/confirm-modal";

function WeblinkRow({
  webLink,
  navigate,
  setDeleteWebLinkId,
}: {
  webLink: WebLink;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigate: any;
  setDeleteWebLinkId: (id: string | null) => void;
}) {
  return (
    <TableRow>
      <TableCell className="font-medium">{webLink.shortTitle}</TableCell>
      <TableCell>{webLink.fullTitle}</TableCell>
      <TableCell>
        {webLink.image ? (
          <img src={webLink.image} alt="thumbnail" className="w-6 h-6" />
        ) : null}
      </TableCell>
      <TableCell>
        <a href={webLink.url} target="_blank" rel="noopener noreferrer">
          {webLink.url}
        </a>
      </TableCell>
      <TableCell>
        {webLink.icon ? (
          <img
            src={`/icons/feather/${webLink.icon}.svg`}
            alt={webLink.icon || "icon"}
            className="w-6 h-6"
          />
        ) : null}
      </TableCell>
      <TableCell>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => {
                navigate({
                  to: `/web-links/${webLink.id}`,
                });
              }}
            >
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setDeleteWebLinkId(webLink.id)}>
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  );
}

export default function CategoriesTable() {
  const webLinksQuery = useSuspenseQuery(webLinksQueryOptions);
  const webLinks = webLinksQuery.data;
  const [deleteWebLinkId, setDeleteWebLinkId] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { mutate: deleteWebLinkMutation, isPending: isLoading } = useMutation({
    mutationFn: deleteWebLink,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["weblinks"] });
      setDeleteWebLinkId(null);
    },
  });

  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader>
        <CardTitle>Web Links</CardTitle>
        <CardDescription>
          Web Links allow you to link any external website into your app.
        </CardDescription>
      </CardHeader>
      <ConfirmModal
        open={Boolean(deleteWebLinkId)}
        onConfirm={() => {
          if (deleteWebLinkId) deleteWebLinkMutation(deleteWebLinkId);
        }}
        onDismiss={() => {
          setDeleteWebLinkId(null);
        }}
        isLoading={isLoading}
      />
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Short Title</TableHead>
              <TableHead>Full Title</TableHead>
              <TableHead>Image</TableHead>
              <TableHead>URL</TableHead>
              <TableHead>Icon</TableHead>
              <TableHead>
                <span className="sr-only">Actions</span>
              </TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {webLinks.map((link) => (
              <WeblinkRow
                key={link.id}
                webLink={link}
                navigate={navigate}
                setDeleteWebLinkId={setDeleteWebLinkId}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <div className="text-xs text-muted-foreground">
          Showing <strong>1-{webLinks.length}</strong> of{" "}
          <strong>{webLinks.length}</strong> Web links
        </div>
      </CardFooter>
    </Card>
  );
}
