import { Toaster } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { AuthState, useAuth } from "@/hooks/useAuth";
import { isProd } from "@/utils/constants";
import { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";

// function RouterSpinner() {
//   const isLoading = useRouterState({ select: (s) => s.status === "pending" });
//   return <Spinner show={isLoading} />;
// }

export const Route = createRootRouteWithContext<{
  auth: AuthState;
  queryClient: QueryClient;
}>()({
  component: RootComponent,
});

function RootComponent() {
  useAuth();

  return (
    <TooltipProvider>
      {/* <RouterSpinner /> */}
      <Outlet />
      <Toaster />
      {!isProd ? <ReactQueryDevtools buttonPosition="bottom-left" /> : null}
      {!isProd ? <TanStackRouterDevtools position="bottom-right" /> : null}
    </TooltipProvider>
  );
}
