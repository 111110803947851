import { z } from "zod";

export const zEmptyStrToUndefined = z.preprocess((arg) => {
  if (typeof arg === "string" && arg === "") {
    return undefined;
  } else {
    return arg;
  }
}, z.string().optional());

export const zodEmptyStrToUndefined = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess((arg) => {
    if (typeof arg === "string" && arg === "") {
      return undefined;
    }
    return arg;
  }, schema);

export const zStringOrDate = z
  .union([
    z.string().refine((val) => !isNaN(Date.parse(val)), {
      message: "Invalid date string",
    }),
    z.date(),
  ])
  .transform((val) => new Date(val));
