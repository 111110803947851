import {
  Categories,
  Category,
} from "zora-api/concerns/categories/categories.router";
import { axios, buildHeaders } from "./axios";
import {
  CategoryInput,
  ReorderCategoriesInput,
  ReorderCategoryItemsInput,
} from "zora-api/concerns/categories/categories.zod";
import { queryOptions } from "@tanstack/react-query";

export const fetchCategories = async () => {
  return axios
    .get<Categories>("/categories", {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const reorderCategories = async (categories: ReorderCategoriesInput) => {
  return axios
    .post<{ success: boolean }>("/categories/reorder", categories, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const categoryQueryOptions = (categoryId: string) =>
  queryOptions({
    queryKey: ["category", categoryId],
    queryFn: ({ queryKey }) => fetchCategory(queryKey[1]),
  });

export const fetchCategory = async (categoryId: string) => {
  return axios
    .get<Category>(`/categories/${categoryId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const removePostFromCategory = async (
  categoryId: string,
  postId: string,
) => {
  return axios
    .delete(`/categories/${categoryId}/${postId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const reorderCategoryItems = async (
  items: ReorderCategoryItemsInput & { categoryId: string },
) => {
  return axios
    .post(`/categories/${items.categoryId}/items/reorder`, items, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const createCategory = async (category: CategoryInput) => {
  return axios
    .post<Category>("/categories", category, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const editCategory = async (
  category: CategoryInput & { id: string },
) => {
  return axios
    .put<Category>(`/categories/${category.id}`, category, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const deleteCategory = async (categoryId: string) => {
  return axios
    .delete(`/categories/${categoryId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};
