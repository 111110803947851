import PostsTable from "@/components/posts/posts-table";
import { TableHeader } from "@/components/posts/table-header";
import { createFileRoute } from "@tanstack/react-router";

function Posts() {
  return (
    <div>
      <TableHeader />
      <PostsTable />
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/posts/")({
  component: () => <Posts />,
});
