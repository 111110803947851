/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { titleCase } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { IconSelector } from "../web-links/icon-selector";
import { PostableTypes } from "zora-api/lib/post-helpers";
import { merge } from "lodash";
import { ImageInput } from "../image-input/image-input";

const storiesSchema = z.object({
  type: z.literal("stories"),
  storyDuration: z.number().min(1).max(60),
});

const verticalHorizontalSchema = z.object({
  type: z.literal("vertical"),
  showThumbnail: z.boolean(),
});

const horizontalSchema = z.object({
  type: z.literal("horizontal"),
  showThumbnail: z.boolean(),
});

const gridSchema = z.object({
  type: z.literal("grid"),
  backgroundColor: z
    .string()
    .refine((value) => /^#[0-9A-Fa-f]{6}$/.test(value), {
      message: "Invalid hex color code. Must be in format #123456",
    })
    .optional(),
  icon: z.string().optional(),
});

// Combine schemas into a discriminated union
const postablesExtrasSchema = z.discriminatedUnion("type", [
  storiesSchema,
  verticalHorizontalSchema,
  horizontalSchema,
  gridSchema,
]);

const wildJsonSchema = z.any();

type PostablesExtrasType = z.infer<typeof postablesExtrasSchema>;

type PostablesExtrasModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: PostablesExtrasType) => void;
  categoryDisplayType: PostablesExtrasType["type"];
  postableType?: PostableTypes;
};

function categoryProperties(categoryDisplayType: PostablesExtrasType["type"]) {
  if (categoryDisplayType === "grid") {
    return { backgroundColor: "", icon: "" };
  }
  if (categoryDisplayType === "stories") {
    return { storyDuration: 1 };
  }
  if (
    categoryDisplayType === "vertical" ||
    categoryDisplayType === "horizontal"
  ) {
    return { showThumbnail: false };
  }
  return {};
}

function postableProperties(postableType: PostableTypes) {
  if (postableType === "richPost") {
    return { backgroundColor: "", icon: "" };
  }
  return {};
}

function getDefaultValue(
  categoryDisplayType: PostablesExtrasType["type"],
  postableType?: PostableTypes,
) {
  const categoryDefaults = categoryProperties(categoryDisplayType);
  const postableDefaults = postableType ? postableProperties(postableType) : {};

  return merge({}, categoryDefaults, postableDefaults);
}

function gridCategoryExtra(form: any) {
  return (
    <>
      <FormField
        control={form.control}
        name="backgroundColor"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Background Color</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <IconSelector form={form} />
    </>
  );
}

function storiesCategoryExtra(form: any) {
  return (
    <FormField
      control={form.control}
      name="storyDuration"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Story Duration (seconds)</FormLabel>
          <FormControl>
            <Input type="number" min={1} max={60} {...field} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function listCategoryExtra(form: any) {
  return null;
}

// Postable-specific components
function basicPostExtra(form: any) {
  return (
    <>
      <FormField
        control={form.control}
        name="backgroundColor"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Rich Post Background</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <IconSelector form={form} />
    </>
  );
}

function richPostExtra(form: any) {
  return (
    <>
      <FormItem>
        <FormLabel>Display Image</FormLabel>
        <FormField
          control={form.control}
          name="image"
          render={({ field }) => (
            <ImageInput
              form={form}
              field={field}
              contentType="image/*"
              displayType="card"
            />
          )}
        />
      </FormItem>
    </>
  );
}

function ExtraData({
  categoryDisplayType,
  postableType,
  form,
}: {
  categoryDisplayType: PostablesExtrasType["type"];
  postableType?: PostableTypes;
  form: any; // Replace with proper form type
}) {
  const categoryComponentMap = {
    grid: gridCategoryExtra,
    stories: storiesCategoryExtra,
    vertical: listCategoryExtra,
    horizontal: listCategoryExtra,
  };

  const postableComponentMap = {
    basicPost: basicPostExtra,
    richPost: richPostExtra,
  };

  const CategoryExtra = categoryComponentMap[categoryDisplayType];
  const PostableExtra = postableType
    ? postableComponentMap[postableType]
    : null;

  return (
    <>
      {CategoryExtra && CategoryExtra(form)}
      {PostableExtra && PostableExtra(form)}
    </>
  );
}

export function PostablesExtrasModal({
  isOpen,
  onClose,
  onSubmit,
  categoryDisplayType,
  postableType,
}: PostablesExtrasModalProps) {
  const form = useForm<PostablesExtrasType>({
    resolver: zodResolver(wildJsonSchema),
    defaultValues: getDefaultValue(categoryDisplayType, postableType),
  });

  const handleSubmit = (data: PostablesExtrasType) => {
    onSubmit(data);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {`${titleCase(categoryDisplayType)} Display Properties`}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-4"
          >
            <ExtraData
              categoryDisplayType={categoryDisplayType}
              postableType={postableType}
              form={form}
            />

            <DialogFooter>
              <Button type="button" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
