import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Spinner } from "./spinner";

export function ConfirmModal({
  open,
  onConfirm,
  onDismiss,
  isLoading,
  title = "Are you absolutely sure?",
  description = "This action cannot be undone. This will permanently delete and remove your data from our servers.",
}: {
  open: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  isLoading?: boolean;
  title?: string;
  description?: string;
}) {
  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onDismiss}>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={onConfirm}>
            Continue
            {isLoading ? <Spinner /> : null}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
