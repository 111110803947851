import { PlaceForm } from "@/components/places/places-form";
import { createFileRoute } from "@tanstack/react-router";

function NewPlace() {
  return <PlaceForm isEdit={false} />;
}

export const Route = createFileRoute("/_authenticated/places/new")({
  component: () => <NewPlace />,
});
