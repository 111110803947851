import dayjs from "@/lib/dayjs";
import { createFileRoute } from "@tanstack/react-router";

function Info() {
  const buildTime = import.meta.env.VITE_BUILD_TIMESTAMP
    ? dayjs(import.meta.env.VITE_BUILD_TIMESTAMP)
        .local()
        .format("MMMM D, YYYY h:mm A")
    : "Development Build";

  return (
    <div>
      <h2>Build Information</h2>
      <div>
        <strong>Build Time:</strong> {buildTime}
      </div>
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/settings/info")({
  component: () => <Info />,
});
