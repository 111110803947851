import { create } from "zustand";

export interface PostablesCommandSearch {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const usePostablesCommandSearch = create<PostablesCommandSearch>(
  (set) => ({
    isOpen: false,
    setIsOpen: (isOpen) => set({ isOpen }),
  }),
);
