import { Postables } from "zora-api/concerns/postables/postables.router";
import { axios, buildHeaders } from "./axios";
import { PostableFilters } from "zora-api/concerns/postables/postables.zod";

export const allPostables = async (filters?: PostableFilters) => {
  return axios
    .post<Postables>("/postables/all", filters, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};
