import { createWebLink, editWebLink } from "@/api/weblinks.api";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { ChevronLeft } from "lucide-react";
import { useForm } from "react-hook-form";
import { WebLink } from "zora-api/concerns/mobile/mobile.router";
import {
  WebLinkInput,
  webLinkZod,
} from "zora-api/concerns/weblinks/weblinks.zod";
import { toast } from "sonner";
import { ImageInput } from "../image-input/image-input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Icons } from "../ui/icons";
import { IconSelector } from "./icon-selector";

type WebLinkFormProps =
  | {
      isEdit: true;
      initialValues: WebLink;
    }
  | {
      isEdit: false;
      initialValues?: WebLink;
    };

export function WebLinksForm({ isEdit, initialValues }: WebLinkFormProps) {
  const router = useRouter();
  const navigate = useNavigate();
  const webLinkId = initialValues?.id;
  const queryClient = useQueryClient();

  const form = useForm<WebLinkInput>({
    resolver: zodResolver(webLinkZod),
    defaultValues: {
      url: initialValues?.url || "",
      shortTitle: initialValues?.shortTitle || "",
      fullTitle: initialValues?.fullTitle || "",
      image: initialValues?.image || "",
      icon: initialValues?.icon || "",
      color: initialValues?.color || "",
    },
  });

  const { mutate: createWebLinkMutation, isPending: isCreatingWebLink } =
    useMutation({
      mutationFn: (webLink: WebLinkInput) => createWebLink(webLink),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["weblinks"] });
        navigate({ to: "/web-links" });
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const { mutate: updateWebLinkMutation, isPending: isUpdatingWebLink } =
    useMutation({
      mutationFn: (webLink: WebLinkInput & { id: string }) =>
        editWebLink(webLink),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["weblinks"] });
        if (webLinkId) {
          queryClient.invalidateQueries({ queryKey: ["weblink", webLinkId] });
        }

        navigate({ to: "/web-links" });
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const isSubmitting = isCreatingWebLink || isUpdatingWebLink;

  const onSubmit = (data: WebLinkInput) => {
    if (isEdit && webLinkId) {
      updateWebLinkMutation({
        ...data,
        id: webLinkId,
      });
    } else {
      createWebLinkMutation({
        ...data,
      });
    }
  };

  return (
    <div className="mx-auto grid max-w-[59rem] flex-1 auto-rows-max gap-4 mt-4">
      <Form {...form}>
        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={() => {
              router.history.back();
            }}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Back</span>
          </Button>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            {isEdit ? "Edit Web Link" : "New Web Link"}
          </h1>
          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => {
                router.history.back();
              }}
            >
              Discard
            </Button>
            <Button
              size="sm"
              onClick={form.handleSubmit(onSubmit)}
              disabled={isSubmitting}
            >
              {isSubmitting ? <Icons.spinner className="h-4 w-4 mr-2" /> : null}
              Save Web Link
            </Button>
          </div>
        </div>

        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <Card x-chunk="dashboard-07-chunk-0">
              <CardHeader>
                <CardTitle>Web Link</CardTitle>
                <CardDescription>
                  Web Links can connect to any website to display content
                  directly in the app.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <div className="grid gap-3 grid-cols-2">
                    <FormField
                      control={form.control}
                      name={"fullTitle"}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Title</FormLabel>
                          <FormControl>
                            <Input
                              autoFocus
                              type="text"
                              className="w-full"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name={"shortTitle"}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Short Title</FormLabel>
                          <FormControl>
                            <Input type="text" className="w-full" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormField
                    control={form.control}
                    name={"url"}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>URL</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>

            <IconSelector form={form} />
          </div>
          <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
            <Card className="overflow-hidden">
              <CardHeader>
                <CardTitle>Cover Image</CardTitle>
                <CardDescription>
                  Select a cover image for your web link
                </CardDescription>
              </CardHeader>
              <CardContent>
                <FormField
                  name={"image"}
                  control={form.control}
                  render={({ field }) => (
                    <ImageInput
                      form={form}
                      field={field}
                      contentType="image/*"
                      displayType="card"
                    />
                  )}
                />
                {form.formState.errors.image && (
                  <FormMessage>
                    {form.formState.errors.image.message}
                  </FormMessage>
                )}
              </CardContent>
            </Card>
            <Card x-chunk="dashboard-07-chunk-2">
              <CardHeader>
                <CardTitle>Display Properties</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <div className="grid gap-3">
                    <FormField
                      control={form.control}
                      name={"color"}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Color (optional)</FormLabel>
                          <FormControl>
                            <Input
                              autoFocus
                              type="text"
                              className="w-full"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2 md:hidden">
          <Button variant="outline" size="sm">
            Discard
          </Button>
          <Button size="sm" onClick={form.handleSubmit(onSubmit)}>
            Save Web Link
          </Button>
        </div>
      </Form>
    </div>
  );
}
