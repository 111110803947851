import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none">
      <path d="M0 0h24v24H0Z" />
      <path
        stroke="#323232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m11.61 16.499-2.958 3.688a.5.5 0 0 0 .39.813h5.916a.5.5 0 0 0 .39-.813l-2.958-3.688a.5.5 0 0 0-.78 0ZM12.39 7.507l2.958-3.688a.5.5 0 0 0-.39-.813H9.042a.5.5 0 0 0-.39.813l2.958 3.688a.5.5 0 0 0 .78 0ZM3 12h1M8 12h2M14 12h2M20 12h1"
      />
    </g>
  </svg>
);
export default SvgComponent;
