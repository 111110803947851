import {
  Categories,
  Category,
} from "zora-api/concerns/categories/categories.router";
import {
  deleteCategory,
  fetchCategories,
  reorderCategories,
} from "@/api/categories.api";
import GridIcon from "@/components/icons/grid-icon";
import HorizontalMoveIcon from "@/components/icons/horizontal-move-icon";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import debounce from "lodash/debounce";
import { MoreHorizontal } from "lucide-react";
import { useEffect, useState } from "react";
import { colorPalette } from "./categories-shared";
import { ConfirmModal } from "../ui/confirm-modal";

function SortableRow({
  category,
  navigate,
  setDeleteCategoryId,
}: {
  category: Category;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigate: any;
  setDeleteCategoryId: (id: string | null) => void;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: category.id });

  const style = {
    transform: transform ? `translate3d(0px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  return (
    <TableRow ref={setNodeRef} style={style}>
      <TableCell
        className="hidden sm:table-cell cursor-move"
        {...attributes}
        {...listeners}
      >
        <HorizontalMoveIcon className="handle h-4 w-4" />
      </TableCell>
      <TableCell className="font-medium">{category.title}</TableCell>
      <TableCell>
        <Badge variant="outline" className="p-2">
          {category.displayType === "grid" && <GridIcon className="h-4 w-4" />}
          {/* {category.displayType === "list" && <ListIcon className="h-4 w-4" />}
          {category.displayType === "carousel" && (
            <CarouselIcon className="h-4 w-4" />
          )} */}
          <span className="ml-1">{category.displayType}</span>
        </Badge>
      </TableCell>
      <TableCell>
        {category.backgroundColor ? (
          <>
            <span
              style={{
                marginTop: "0.25em",
                display: "inline-block",
                width: "1em",
                height: "1em",
                borderRadius: "50%",
                backgroundColor: category.backgroundColor,
                marginRight: "0.5em",
                border: "1px solid #000",
              }}
            />
            {
              colorPalette.find(
                (color) => color.hex === category.backgroundColor,
              )?.name
            }
          </>
        ) : null}
      </TableCell>
      <TableCell className="hidden md:table-cell">
        {category.postables.length}
      </TableCell>
      <TableCell className="hidden md:table-cell">
        <Badge variant={"outline"}>{category.order + 1}</Badge>
      </TableCell>
      <TableCell>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => {
                navigate({
                  to: `/app/feed/categories/${category.id}`,
                });
              }}
            >
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setDeleteCategoryId(category.id)}>
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  );
}

export default function CategoriesTable() {
  const [deleteCategoryId, setDeleteCategoryId] = useState<string | null>(null);
  const [categories, setCategories] = useState<Categories>([]);
  const { data } = useQuery({
    queryKey: ["categories"],
    queryFn: () => {
      return fetchCategories();
    },
  });
  const queryClient = useQueryClient();

  const { mutate: deleteCategoryMutation, isPending: isLoading } = useMutation({
    mutationFn: deleteCategory,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["categories"] });
      setDeleteCategoryId(null);
    },
  });

  const { mutateAsync } = useMutation({
    mutationFn: reorderCategories,
  });

  useEffect(() => {
    if (data) {
      setCategories(data);
    }
  }, [data]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const updateCategoriesOrderApi = (updatedCategories: Categories) => {
    mutateAsync({ categories: updatedCategories }).catch((e) => {
      console.error(e);
    });
  };

  const navigate = useNavigate();
  const debouncedUpdateOrder = debounce(updateCategoriesOrderApi, 2500);

  const handleDragEnd = (event: {
    active: { id: number | string };
    over: { id: number | string } | null;
  }) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setCategories((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);

        const updatedItems = newItems.map((item, index) => ({
          ...item,
          order: index,
        }));

        debouncedUpdateOrder(updatedItems);

        return updatedItems;
      });
    }
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>Categories</CardTitle>
        <CardDescription>
          Categories allow you to organize your feed into different sections.
        </CardDescription>
      </CardHeader>
      <ConfirmModal
        open={Boolean(deleteCategoryId)}
        onConfirm={() => {
          if (deleteCategoryId) deleteCategoryMutation(deleteCategoryId);
        }}
        onDismiss={() => {
          setDeleteCategoryId(null);
        }}
        isLoading={isLoading}
      />
      <CardContent>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={categories}
            strategy={verticalListSortingStrategy}
          >
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="hidden w-[100px] sm:table-cell">
                    <span className="sr-only">Image</span>
                  </TableHead>
                  <TableHead>Title</TableHead>
                  <TableHead>Display Type</TableHead>
                  <TableHead>Color</TableHead>
                  <TableHead className="hidden md:table-cell">Items</TableHead>
                  <TableHead className="hidden md:table-cell">Order</TableHead>
                  <TableHead>
                    <span className="sr-only">Actions</span>
                  </TableHead>
                </TableRow>
              </TableHeader>

              <TableBody>
                {categories.map((category) => (
                  <SortableRow
                    key={category.id}
                    category={category}
                    navigate={navigate}
                    setDeleteCategoryId={setDeleteCategoryId}
                  />
                ))}
              </TableBody>
            </Table>
          </SortableContext>
        </DndContext>
      </CardContent>
      <CardFooter>
        <div className="text-xs text-muted-foreground">
          Showing <strong>1-{categories.length}</strong> of{" "}
          <strong>{categories.length}</strong> categories
        </div>
      </CardFooter>
    </Card>
  );
}
