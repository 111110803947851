import { deletePlace, placesQueryOptions } from "@/api/places.api";
import { TableHeader } from "@/components/tables/add-header";
import { DropdownItem } from "@/components/tables/data-row";
import { DataTable } from "@/components/tables/data-table";
import { ConfirmModal } from "@/components/ui/confirm-modal";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { ColumnDef } from "@tanstack/react-table";
import { useState } from "react";
import { Place } from "zora-api/prisma/generated";

const columns: ColumnDef<Place>[] = [
  {
    accessorKey: "title",
    header: "Title",
  },
  {
    accessorKey: "type",
    header: "Type",
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "address",
    header: "Address",
  },
];

function PlacesRoute() {
  const placesQuery = useSuspenseQuery(placesQueryOptions);
  const places = placesQuery.data;
  const [deleteEventId, setDeleteEventId] = useState<string | null>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: deletePlaceMutation, isPending: isLoading } = useMutation({
    mutationFn: deletePlace,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["places"] });
      setDeleteEventId(null);
    },
  });

  const dropdownItems: DropdownItem<Place>[] = [
    {
      label: "Edit",
      onClick: ({ id }) => {
        navigate({
          to: `/places/${id}`,
        });
      },
    },
    {
      label: "Delete",
      onClick: ({ id }) => {
        setDeleteEventId(id);
      },
    },
  ];

  return (
    <div>
      <TableHeader linkTo="/places/new" buttonText="Add Place" />
      <DataTable
        data={places}
        columns={columns}
        title="Places"
        description="Manage your places"
        dropdownItems={dropdownItems}
      />
      <ConfirmModal
        open={Boolean(deleteEventId)}
        onConfirm={() => {
          if (deleteEventId) deletePlaceMutation(deleteEventId);
        }}
        onDismiss={() => {
          setDeleteEventId(null);
        }}
        isLoading={isLoading}
      />
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/places/")({
  component: () => <PlacesRoute />,
});
