import { z } from "zod";

export const createStoryZodForm = z.object({
  fullTitle: z.string(),
  shortTitle: z.string(),
  url: z.string(),
  image: z.string(),
});

export type CreateStoryInput = z.infer<typeof createStoryZodForm>;

export const editStoryZodForm = createStoryZodForm.extend({
  storyId: z.string(),
});

export type EditStoryInput = z.infer<typeof editStoryZodForm>;
