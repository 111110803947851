import { z } from "zod";
import { postableTypes, postableZod } from "../postables/postables.zod.js";

export const registerDeviceZod = z.object({
  token: z.string(),
  deviceId: z.string(),
});

export type RegisterDeviceInput = z.infer<typeof registerDeviceZod>;

export const notificationsFormDataZod = z.object({
  title: z.string(),
  body: z.string(),
  sendAt: z.string(),
  postable: postableZod.optional(),
  pushTokens: z.array(z.string()).optional(),
});

export type NotificationsFormData = z.infer<typeof notificationsFormDataZod>;

export const saveNotificationTimeInputZod = z.object({
  notificationTime: z.string().refine((val) => !isNaN(Date.parse(val)), {
    message: "Invalid date format",
  }),
  postableType: postableTypes,
  postableId: z.string(),
});

export type SaveNotificationTimeInput = z.infer<
  typeof saveNotificationTimeInputZod
>;
