import { z } from "zod";

export const postsZodForm = z.object({
  title: z.string().min(1),
  body: z.string(),
  image: z.string().url().optional(),
  publishDate: z.string().datetime().optional(),
  tiptapJson: z.any().optional(),
});

export type CreateBasicPostInput = z.infer<typeof postsZodForm>;

export const editBasicPostZod = postsZodForm.extend({
  id: z.string(),
});

export type EditBasicPostInput = z.infer<typeof editBasicPostZod>;
