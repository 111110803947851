import {
  LoginInput,
  ResetPasswordInput,
} from "zora-api/concerns/auth/auth.zod";
import {
  LoginResponse,
  AudienceResetPasswordResponse,
  UserResetPasswordResponse,
} from "zora-api/concerns/auth/auth.router";
import { axios, buildHeaders } from "./axios";

export async function postLogin(input: LoginInput) {
  return axios
    .post<LoginResponse>("/auth/users/login", input)
    .then((res) => res.data);
}

export const selectOrganization = async (
  organizationId: string,
): Promise<LoginResponse> => {
  return axios
    .post(
      "/auth/users/select-org",
      { newOrgId: organizationId },
      { headers: buildHeaders() },
    )
    .then((r) => r.data);
};

export async function resetPassword(
  input: ResetPasswordInput & { orgId: string },
) {
  return axios
    .post<AudienceResetPasswordResponse>(
      "/auth/audience/reset-password",
      input,
      {
        headers: {
          "Content-Type": "application/json",
          "x-org-id": input.orgId,
        },
      },
    )
    .then((res) => res.data);
}

export async function resetUserPassword(
  input: ResetPasswordInput & { orgId: string },
) {
  return axios
    .post<UserResetPasswordResponse>("/auth/users/reset-password", input, {
      headers: {
        "Content-Type": "application/json",
        "x-org-id": input.orgId,
      },
    })
    .then((res) => res.data);
}
