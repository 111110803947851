import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { Link, useMatchRoute } from "@tanstack/react-router";
import {
  Calendar,
  FileText,
  Home,
  Link2 as LinkIcon,
  Rss,
  // Settings, // Renamed to avoid conflict with the router's Link
  User,
  Map,
  AlertTriangle,
  Bell,
} from "lucide-react";

export function Sidebar() {
  const matchRoute = useMatchRoute();
  const home = matchRoute({ to: "/home", fuzzy: true });
  const app = matchRoute({ to: "/app", fuzzy: true });
  const events = matchRoute({ to: "/calendar", fuzzy: true });
  const posts = matchRoute({ to: "/posts", fuzzy: true });
  const residents = matchRoute({ to: "/residents", fuzzy: true });
  const links = matchRoute({ to: "/web-links", fuzzy: true });
  const incidents = matchRoute({ to: "/incidents", fuzzy: true });
  const places = matchRoute({ to: "/places", fuzzy: true });
  const notifications = matchRoute({ to: "/notifications", fuzzy: true });

  return (
    <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
      <nav className="flex flex-col items-center gap-4 px-2 sm:py-4">
        <Link
          to="/home"
          className={cn(
            "group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full text-lg font-semibold md:h-8 md:w-8 md:text-base",
            {
              "bg-primary text-primary-foreground": home,
              "text-muted-foreground hover:text-foreground": !home,
            },
          )}
        >
          <Home className="h-4 w-4 transition-all group-hover:scale-110" />
          <span className="sr-only">Vineyard City</span>
        </Link>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              to="/app"
              className={cn(
                "flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8",
                {
                  "bg-primary text-primary-foreground": app,
                  "text-muted-foreground hover:text-foreground": !app,
                },
              )}
            >
              <Rss className="h-5 w-5 transition-all group-hover:scale-110" />
              <span className="sr-only">App</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">App</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              to="/web-links"
              className={cn(
                "flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8",
                {
                  "bg-primary text-primary-foreground": links,
                  "text-muted-foreground hover:text-foreground": !links,
                },
              )}
            >
              <LinkIcon className="h-5 w-5" />
              <span className="sr-only">Web Links</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Web Links</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              to="/posts"
              className={cn(
                "flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8",
                {
                  "bg-primary text-primary-foreground": posts,
                  "text-muted-foreground hover:text-foreground": !posts,
                },
              )}
            >
              <FileText className="h-5 w-5 transition-all group-hover:scale-110" />
              <span className="sr-only">Posts</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Posts</TooltipContent>
        </Tooltip>
        {/* <Tooltip>
          <TooltipTrigger asChild>
            <Link
              to="/stories"
              className={cn(
                "flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8",
                {
                  "bg-primary text-primary-foreground": stories,
                  "text-muted-foreground hover:text-foreground": !stories,
                },
              )}
            >
              <LinkIcon className="h-5 w-5 transition-all group-hover:scale-110" />
              <span className="sr-only">Stories</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Stories</TooltipContent>
        </Tooltip> */}
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              // @ts-expect-error - TODO: fix this
              to="/calendar/all/events"
              className={cn(
                "flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8",
                {
                  "bg-primary text-primary-foreground": events,
                  "text-muted-foreground hover:text-foreground": !events,
                },
              )}
            >
              <Calendar className="h-5 w-5 transition-all group-hover:scale-110" />
              <span className="sr-only">Events</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Events</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              to="/residents"
              className={cn(
                "flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8",
                {
                  "bg-primary text-primary-foreground": residents,
                  "text-muted-foreground hover:text-foreground": !residents,
                },
              )}
            >
              <User className="h-5 w-5" />
              <span className="sr-only">Residents</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Residents</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              to="/places"
              className={cn(
                "flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8",
                {
                  "bg-primary text-primary-foreground": places,
                  "text-muted-foreground hover:text-foreground": !places,
                },
              )}
            >
              <Map className="h-5 w-5" />
              <span className="sr-only">Places</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Places</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              to="/incidents"
              className={cn(
                "flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8",
                {
                  "bg-primary text-primary-foreground": incidents,
                  "text-muted-foreground hover:text-foreground": !incidents,
                },
              )}
            >
              <AlertTriangle className="h-5 w-5" />
              <span className="sr-only">Incidents</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Incidents</TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              to="/notifications"
              className={cn(
                "flex h-9 w-9 items-center justify-center rounded-lg transition-colors md:h-8 md:w-8",
                {
                  "bg-primary text-primary-foreground": notifications,
                  "text-muted-foreground hover:text-foreground": !notifications,
                },
              )}
            >
              <Bell className="h-5 w-5" />
              <span className="sr-only">Notifications</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Notifications</TooltipContent>
        </Tooltip>
      </nav>
      {/* <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-4">
        <Tooltip>
          <TooltipTrigger asChild>
            <Link
              href="#"
              className="flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8"
            >
              <Settings className="h-5 w-5" />
              <span className="sr-only">Settings</span>
            </Link>
          </TooltipTrigger>
          <TooltipContent side="right">Settings</TooltipContent>
        </Tooltip>
      </nav> */}
    </aside>
  );
}
