import { createBasicPost, editBasicPost } from "@/api/posts.api";
import { FormHeader } from "@/components/form/form-header";
import { TipyTap } from "@/components/tipytap/tipytap";
import { tiptapExtensions } from "@/components/tipytap/tipytap-helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Content, useEditor } from "@tiptap/react";
import { Post } from "zora-api/concerns/mobile/mobile.router";
import { CreateBasicPostInput } from "zora-api/concerns/posts/posts.zod";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractTitleFromContent = (content: any) => {
  const headings = content.content.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (node: any) =>
      ["heading"].includes(node.type) && [1, 2, 3].includes(node.attrs.level),
  );
  return headings ? headings.content[0].text : "New Page";
};

export function RichEditor({ post }: { post?: Post }) {
  const queryClient = useQueryClient();
  const postId = post?.id;
  const { mutate: createPostMutation } = useMutation({
    mutationFn: (post: CreateBasicPostInput) => createBasicPost(post),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      navigate({ to: "/posts" });
    },
  });

  const { mutate: updatePostMutation } = useMutation({
    mutationFn: (post: CreateBasicPostInput & { id: string }) =>
      editBasicPost(post),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      if (postId) queryClient.invalidateQueries({ queryKey: ["post", postId] });
      navigate({ to: "/posts" });
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSave = (post: CreateBasicPostInput, tiptapJson: any) => {
    if (postId) {
      updatePostMutation({ ...post, id: postId, tiptapJson });
    } else {
      createPostMutation({ ...post, tiptapJson });
    }
  };

  const navigate = useNavigate();
  const editor = useEditor({
    extensions: tiptapExtensions,
    content: (post?.tiptapJson as Content) || {
      type: "doc",
      content: [
        {
          type: "heading",
          attrs: { level: 1 },
          content: [{ type: "text", text: "Welcome to the Post builder" }],
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "This is a demonstration of the various formatting options available in this editor.",
            },
          ],
        },
        {
          type: "heading",
          attrs: { level: 2 },
          content: [{ type: "text", text: "Basic Formatting" }],
        },
        {
          type: "paragraph",
          content: [
            { type: "text", text: "You can make text " },
            { type: "text", marks: [{ type: "bold" }], text: "bold" },
            { type: "text", text: ", " },
            { type: "text", marks: [{ type: "italic" }], text: "italic" },
            { type: "text", text: ", or " },
            {
              type: "text",
              marks: [{ type: "underline" }],
              text: "underlined",
            },
            { type: "text", text: ". You can also " },
            {
              type: "text",
              marks: [{ type: "strike" }],
              text: "strike through",
            },
            { type: "text", text: " text." },
          ],
        },
        {
          type: "heading",
          attrs: { level: 2 },
          content: [{ type: "text", text: "Images" }],
        },
        {
          type: "paragraph",
          content: [
            { type: "text", text: "You can insert images into your content:" },
          ],
        },
        {
          type: "image",
          attrs: {
            src: "https://placehold.co/600x400?text=Sample+Image",
            alt: "Sample Image",
          },
        },
        {
          type: "heading",
          attrs: { level: 2 },
          content: [{ type: "text", text: "Quotes" }],
        },
        {
          type: "blockquote",
          content: [
            {
              type: "paragraph",
              content: [
                {
                  type: "text",
                  text: '"This is an example of a blockquote. Use it to highlight important information or quotes."',
                },
              ],
            },
          ],
        },
        {
          type: "heading",
          attrs: { level: 2 },
          content: [{ type: "text", text: "Lists" }],
        },
        {
          type: "paragraph",
          content: [
            { type: "text", text: "Create ordered and unordered lists:" },
          ],
        },
        {
          type: "bulletList",
          content: [
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [{ type: "text", text: "Unordered item 1" }],
                },
              ],
            },
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [{ type: "text", text: "Unordered item 2" }],
                },
              ],
            },
          ],
        },
        {
          type: "orderedList",
          content: [
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [{ type: "text", text: "Ordered item 1" }],
                },
              ],
            },
            {
              type: "listItem",
              content: [
                {
                  type: "paragraph",
                  content: [{ type: "text", text: "Ordered item 2" }],
                },
              ],
            },
          ],
        },
        {
          type: "heading",
          attrs: { level: 2 },
          content: [{ type: "text", text: "Advanced Formatting" }],
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "Experiment with different heading levels, text colors, and more using the toolbar above.",
            },
          ],
        },
        {
          type: "horizontalRule",
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              marks: [{ type: "italic" }],
              text: "Use this editor to create engaging content.",
            },
          ],
        },
      ],
    },
  });

  return (
    <div>
      <FormHeader
        title={post?.title || "New Page"}
        onBack={() => {
          navigate({ to: "/posts" });
        }}
        onSave={() => {
          const tiptapJson = editor?.getJSON();
          const title = extractTitleFromContent(tiptapJson);
          handleSave({ title: title || "New Page", body: "" }, tiptapJson);
        }}
        isLoading={false}
        primaryButtonText="Save"
      />
      <TipyTap tiptapEditor={editor} />
    </div>
  );
}
