import debounce from "lodash/debounce";
import { Badge } from "@/components/ui/badge";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useEffect, useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import { useQuery } from "@tanstack/react-query";
import { fetchStories } from "@/api/stories.api";
import { Story } from "zora-api/concerns/mobile/mobile.router";

export default function FeedStoriesTable() {
  const { data, isLoading } = useQuery({
    queryKey: ["stories"],
    queryFn: fetchStories,
  });

  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);

  const [rows, setRows] = useState<Story[]>([]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const updateStoriesOrderApi = (updatedStories: Story[]) => {
    console.log("API call to update order:", updatedStories);
    // Replace with actual API call
  };

  const debouncedUpdateOrder = debounce(updateStoriesOrderApi, 1700);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setRows((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(items, oldIndex, newIndex);

        const updatedItems = newItems.map((item, index) => ({
          ...item,
          order: index,
        }));

        // Call the debounced API update function
        debouncedUpdateOrder(updatedItems);

        return updatedItems;
      });
    }
  };

  if (isLoading) return null;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={rows} strategy={verticalListSortingStrategy}>
        <Card>
          <CardHeader className="px-7 flex-row gap-4">
            <div>
              <CardTitle>Stories</CardTitle>
              <CardDescription>
                Stories appear at the top of the feed in a horizontal scroll
                area. Each story contains an image and a title. When a user
                clicks on a story, they are taken to the story's page.
              </CardDescription>
            </div>
            <div>
              <Button>New Story</Button>
            </div>
          </CardHeader>
          <CardContent>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>
                    <span className="sr-only">Drag</span>
                  </TableHead>
                  <TableHead>Image</TableHead>
                  <TableHead>Link</TableHead>
                  <TableHead className="text-right">Order</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {rows.map((row) => (
                  <SortableRow key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </SortableContext>
    </DndContext>
  );
}

function SortableRow({ row }: { row: Story }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: row.id });

  const style = {
    transform: transform ? `translate3d(0px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  return (
    <TableRow ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <TableCell className="cursor-move">
        <div className="handle">:::</div>
      </TableCell>
      <TableCell>
        <Avatar>
          <AvatarImage src={row.image} alt={row.fullTitle} />
          <AvatarFallback>{row.fullTitle.slice(0, 2)}</AvatarFallback>
        </Avatar>
      </TableCell>
      <TableCell>
        <a href={row.url} target="_blank" rel="noreferrer">
          {row.url}
        </a>
      </TableCell>
      <TableCell className="text-right">
        <Badge variant={"outline"}>{row.order + 1}</Badge>
      </TableCell>
    </TableRow>
  );
}
