import { deleteMedia, mediaQueryOptions } from "@/api/media.api";
import { TableHeader } from "@/components/tables/add-header";
import { DropdownItem } from "@/components/tables/data-row";
import { DataTable } from "@/components/tables/data-table";
import { ConfirmModal } from "@/components/ui/confirm-modal";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { ColumnDef } from "@tanstack/react-table";
import { useState } from "react";
import { MediaResponse } from "zora-api/concerns/media/media.router";

const columns: ColumnDef<MediaResponse>[] = [
  {
    accessorKey: "title",
    header: "Title",
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "cloudStorageObjectId",
    header: "Storage Object ID",
  },
];

function MediaRoute() {
  const mediaQuery = useSuspenseQuery(mediaQueryOptions);
  const media = mediaQuery.data;
  const [deleteMediaId, setDeleteMediaId] = useState<string | null>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: deleteMediaMutation, isPending: isLoading } = useMutation({
    mutationFn: deleteMedia,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["media"] });
      setDeleteMediaId(null);
    },
  });

  const dropdownItems: DropdownItem<MediaResponse>[] = [
    {
      label: "Edit",
      onClick: ({ id }) => {
        navigate({
          to: `/media/${id}`,
        });
      },
    },
    {
      label: "Delete",
      onClick: ({ id }) => {
        setDeleteMediaId(id);
      },
    },
  ];

  return (
    <div>
      <TableHeader linkTo="/media/new" buttonText="Add Media" />
      <DataTable
        data={media}
        columns={columns}
        title="Media"
        description="Manage your media files"
        dropdownItems={dropdownItems}
      />
      <ConfirmModal
        open={Boolean(deleteMediaId)}
        onConfirm={() => {
          if (deleteMediaId) deleteMediaMutation(deleteMediaId);
        }}
        onDismiss={() => {
          setDeleteMediaId(null);
        }}
        isLoading={isLoading}
      />
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/media/")({
  component: () => <MediaRoute />,
});
