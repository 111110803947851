import { categoryQueryOptions } from "@/api/categories.api";
import { CategoryForm } from "@/components/categories/category-form";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const parseParams = z.object({
  categoryId: z.string(),
});

function EditCategory() {
  const categoryId = Route.useParams().categoryId;
  const categoryQuery = useSuspenseQuery(categoryQueryOptions(categoryId));
  const category = categoryQuery.data;

  return <CategoryForm isEdit initialValues={category} />;
}

export const Route = createFileRoute(
  "/_authenticated/app/feed/categories/$categoryId",
)({
  component: EditCategory,
  parseParams: (params) => parseParams.parse(params),
  loader: ({ context: { queryClient }, params }) =>
    queryClient.ensureQueryData(categoryQueryOptions(params.categoryId)),
});
