import { UserClaims } from "zora-api/concerns/auth/auth.service";
import { persist, createJSONStorage } from "zustand/middleware";
import { create } from "zustand";

export interface AuthState {
  jwt: string | null;
  setJwt: (jwt: string | null) => void;
  claims: UserClaims | null;
  setClaims: (claims: UserClaims | null) => void;
  logout: () => void;
  login: (jwt: string, claims: UserClaims) => void;
  setOrgPhoneNumber: (orgPhoneNumber: string | null) => void;
  orgPhoneNumber: string | null;
}

export const authKey = "auth";
export const orgKey = "org";

export const useAuth = create(
  persist<AuthState>(
    (set) => ({
      logout: () => {
        set({ jwt: null, claims: null });
        window.localStorage.removeItem(authKey);
        window.localStorage.removeItem(orgKey);
      },
      login: (jwt: string, claims: UserClaims) => {
        set({ jwt, claims });
        window.localStorage.setItem(authKey, jwt);
        window.localStorage.setItem(orgKey, claims.orgId);
      },
      jwt: null,
      claims: null,
      setClaims: (claims: UserClaims | null) => {
        if (claims) window.localStorage.setItem(orgKey, claims.orgId);
        set({ claims });
      },
      setJwt: (jwt: string | null) => {
        if (jwt) window.localStorage.setItem(authKey, jwt);
        set({ jwt });
      },
      setOrgPhoneNumber: (orgPhoneNumber: string | null) => {
        set({ orgPhoneNumber });
      },
      orgPhoneNumber: null,
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
