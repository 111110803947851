import { Button } from "@/components/ui/button";
import { ChevronLeft } from "lucide-react";
import { Icons } from "../ui/icons";

export function FormHeader({
  title,
  primaryButtonText,
  onBack,
  onSave,
  isLoading,
}: {
  title: string;
  primaryButtonText: string;
  onBack: () => void;
  onSave: () => void;
  isLoading: boolean;
}) {
  return (
    <div className="flex items-center gap-4">
      <Button
        variant="outline"
        size="icon"
        className="h-7 w-7"
        onClick={onBack}
      >
        <ChevronLeft className="h-4 w-4" />
        <span className="sr-only">Back</span>
      </Button>
      <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
        {title}
      </h1>
      <div className="hidden items-center gap-2 md:ml-auto md:flex">
        <Button type="button" variant="outline" size="sm" onClick={onBack}>
          Discard
        </Button>
        <Button size="sm" onClick={onSave} disabled={isLoading}>
          {isLoading ? <Icons.spinner className="h-4 w-4 mr-2" /> : null}
          {primaryButtonText}
        </Button>
      </div>
    </div>
  );
}
