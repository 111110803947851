/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  createConversationItem,
  fetchConversation,
} from "@/api/conversations.api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ChatList } from "./chat-list";
import ChatTopbar from "./chat-topbar";
import { Message, userData } from "./data";
import { CreateConversationItemSchema } from "zora-api/concerns/conversations/conversations.zod";

interface ChatProps {
  isMobile: boolean;
  conversationId: string;
  orgPhoneNumber: string;
}

export function Chat({ isMobile, conversationId, orgPhoneNumber }: ChatProps) {
  const queryClient = useQueryClient();
  const { data, isLoading, error } = useQuery({
    queryKey: ["conversation", conversationId],
    queryFn: () =>
      fetchConversation({
        conversationId,
      }),
  });

  const participant = data?.participants.find(
    (participant) => participant.participantId === orgPhoneNumber,
  );

  const participantId = participant?.id;

  const { mutate: createConversationItemMutation } = useMutation({
    mutationFn: createConversationItem,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["conversation", conversationId],
      });
    },
  });

  const sendMessage = (newMessage: CreateConversationItemSchema) => {
    createConversationItemMutation({
      conversationId,
      ...newMessage,
    });
  };

  const createNewConversation = (newMessage: CreateConversationItemSchema) => {
    console.log(newMessage);
  };

  const participants = data?.participants;
  return (
    <div className="flex flex-col justify-between w-full h-full">
      <ChatTopbar participants={participants} />

      <ChatList
        conversationItems={data?.conversationItems || []}
        sendMessage={sendMessage}
        participantId={participantId || ""}
        isMobile={isMobile}
      />
    </div>
  );
}
