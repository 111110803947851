import { NotificationForm } from "@/components/notifications/notifications-form";
import { createFileRoute } from "@tanstack/react-router";

function NewNotification() {
  return <NotificationForm isEdit={false} />;
}

export const Route = createFileRoute("/_authenticated/notifications/new")({
  component: () => <NewNotification />,
});
