import { create } from "zustand";

export interface DeleteModalState {
  toDeleteId: string | null;
  setToDeleteId: (id: string | null) => void;
}

export const useDeleteEvent = create<DeleteModalState>((set) => ({
  toDeleteId: null,
  setToDeleteId: (id: string | null) => set(() => ({ toDeleteId: id })),
}));
