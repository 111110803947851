import {
  createStory,
  deleteStory,
  editStory,
  storiesQueryOptions,
} from "@/api/stories.api";
import { columns } from "@/components/stories/stories.columns";
import { DataTable } from "@/components/table/table";
import { useDeleteEvent } from "@/hooks/useDeleteModal";
import { useStoriesModal } from "@/hooks/useStoriesModal";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import {
  CreateStoryInput,
  createStoryZodForm,
} from "zora-api/concerns/stories/stories.zod";
import { StoriesModalForm } from "./stories/stories-modal-form";
import { Button } from "./ui/button";
import { ConfirmModal } from "./ui/confirm-modal";

export function Stories() {
  const storiesQuery = useSuspenseQuery(storiesQueryOptions);
  const stories = storiesQuery.data;
  const queryClient = useQueryClient();
  const deleteStoryId = useDeleteEvent((s) => s.toDeleteId);
  const setDeleteStoryId = useDeleteEvent((s) => s.setToDeleteId);

  const isStoryModalOpen = useStoriesModal((s) => s.isOpen);
  const setIsStoryModalOpen = useStoriesModal((s) => s.setIsModalOpen);
  const setStoryId = useStoriesModal((s) => s.setStoryId);
  const storyId = useStoriesModal((s) => s.storyId);
  const defaultValues = useStoriesModal((s) => s.defaultValues);
  const setDefaultValues = useStoriesModal((s) => s.setDefaultValues);

  const form = useForm<CreateStoryInput>({
    resolver: zodResolver(createStoryZodForm),
    defaultValues: {
      shortTitle: defaultValues?.shortTitle || "",
      fullTitle: defaultValues?.fullTitle || "",
      image: defaultValues?.image || "",
    },
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [form, defaultValues]);

  const { mutate: deleteStoryMutation, isPending: isLoading } = useMutation({
    mutationFn: deleteStory,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stories"] });
      setDeleteStoryId(null);
    },
  });

  const { mutate: createStoryMutation, isPending: isCreateLoading } =
    useMutation({
      mutationFn: createStory,
      onSuccess: () => {
        form.reset();
        queryClient.invalidateQueries({ queryKey: ["stories"] });
        setStoryId(null);
        toast.success("Story created successfully");
        setIsStoryModalOpen(false);
      },
    });

  const { mutate: editStoryMutation, isPending: isEditLoading } = useMutation({
    mutationFn: editStory,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stories"] });
      setStoryId(null);
      setIsStoryModalOpen(false);
      setDefaultValues({});
      form.reset();
    },
  });

  return (
    <div className="flex flex-1 p-8 justify-around gap-8">
      <div className="flex flex-col space-y-8 w-full xl:w-3/4 lg:max-w-4xl xl:max-w-6xl">
        <div className="flex items-center justify-between space-y-2">
          <div>
            <h2 className="text-2xl font-bold tracking-tight">Stories</h2>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              onClick={() => {
                setDefaultValues({});
                form.reset({
                  shortTitle: "",
                  fullTitle: "",
                  image: "",
                });
                setIsStoryModalOpen(true);
              }}
            >
              New Story
            </Button>
          </div>
        </div>

        <StoriesModalForm
          isModalOpen={isStoryModalOpen}
          setIsModalOpen={setIsStoryModalOpen}
          form={form}
          onSubmit={(data) =>
            storyId
              ? editStoryMutation({ ...data, storyId: storyId })
              : createStoryMutation(data)
          }
          isLoading={isCreateLoading || isEditLoading}
        />

        <ConfirmModal
          open={Boolean(deleteStoryId)}
          onConfirm={() => {
            if (deleteStoryId) deleteStoryMutation(deleteStoryId);
          }}
          onDismiss={() => {
            setDeleteStoryId(null);
          }}
          isLoading={isLoading}
        />
        <DataTable data={stories} columns={columns} filterColumn="fullTitle" />
      </div>
    </div>
  );
}
