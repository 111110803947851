import { z } from "zod";

export const reorderSectionsZod = z.object({
  sections: z.array(
    z.object({
      id: z.string(),
      order: z.number(),
    }),
  ),
});

export type ReorderSectionsInput = z.infer<typeof reorderSectionsZod>;

export const reorderSectionItemsZod = z.object({
  items: z.array(
    z.object({
      id: z.string(),
      order: z.number(),
    }),
  ),
});

export type ReorderSectionItemsInput = z.infer<typeof reorderSectionItemsZod>;

const itemZod = z.object({
  id: z.string().optional(),
  postableId: z.string(),
  postableType: z.string(),
  order: z.number().optional().default(0),
  postable: z.any(),
  deleted: z.boolean().optional().default(false),
});

export const sectionZod = z.object({
  title: z.string().optional(),
  order: z.number().optional(),
  displayType: z.enum(["list", "tiles"]),
  postables: z.array(itemZod),
});

export type SectionInput = z.infer<typeof sectionZod>;
