import { placeQueryOptions } from "@/api/places.api";
import { PlaceForm } from "@/components/places/places-form";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const routeParamsSchema = z.object({
  placeId: z.string(),
});

function EditPlace() {
  const placeId = Route.useParams().placeId;
  const placeQuery = useSuspenseQuery(placeQueryOptions(placeId));
  const place = placeQuery.data;

  return <PlaceForm isEdit={true} initialValues={place} />;
}

export const Route = createFileRoute("/_authenticated/places/$placeId")({
  component: () => <EditPlace />,
  parseParams: (params) => routeParamsSchema.parse(params),
  loader: ({ context: { queryClient }, params }) =>
    queryClient.ensureQueryData(placeQueryOptions(params.placeId)),
});
