import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";
import { toast } from "sonner";

export const Route = createFileRoute("/_public/reset-success")({
  component: ResetSuccessPage,
});

function ResetSuccessPage() {
  useEffect(() => {
    toast("Your password has been reset successfully.");
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen p-4">
      <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
        Your password has been reset
      </h1>
    </div>
  );
}
