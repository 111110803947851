/* eslint-disable @typescript-eslint/no-explicit-any */
import { residentsQueryOptions } from "@/api/residents.api";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Audience } from "zora-api/concerns/audiences/audience.router";

function ResidentRow({
  resident,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  navigate,
}: {
  resident: Audience;
  navigate: any;
}) {
  return (
    <TableRow>
      <TableCell className="font-medium">{resident.fullName}</TableCell>
      <TableCell>{resident.email}</TableCell>
      <TableCell>{resident.phoneNumber}</TableCell>
      <TableCell>{new Date(resident.createdAt).toLocaleString()}</TableCell>
      <TableCell>
        {/* <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => {
                navigate({
                  to: `/residents/${resident.id}`,
                });
              }}
            >
              Edit
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu> */}
      </TableCell>
    </TableRow>
  );
}

export default function ResidentsTable() {
  const residentsQuery = useSuspenseQuery(residentsQueryOptions);
  const residents = residentsQuery.data;
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader>
        <CardTitle>Residents</CardTitle>
        <CardDescription>
          Manage your community's residents and their information.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Full Name</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Phone Number</TableHead>
              <TableHead>Sign Up Date</TableHead>
              <TableHead>
                <span className="sr-only">Actions</span>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {residents.map((resident) => (
              <ResidentRow
                key={resident.id}
                resident={resident}
                navigate={navigate}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <div className="text-xs text-muted-foreground">
          Showing <strong>1-{residents.length}</strong> of{" "}
          <strong>{residents.length}</strong> residents
        </div>
      </CardFooter>
    </Card>
  );
}
