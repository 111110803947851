import { CategoryForm } from "@/components/categories/category-form";
import { createFileRoute } from "@tanstack/react-router";

function NewCategory() {
  return <CategoryForm isEdit={false} />;
}

export const Route = createFileRoute("/_authenticated/app/feed/categories/new")(
  {
    component: NewCategory,
  },
);
