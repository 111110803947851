import { isEmpty } from "lodash";
import { BasicPost } from "../prisma/generated/index.js";

export type PostableTypes = "basicPost" | "richPost";

type BasicPostable = {
  postableType: "basicPost";
  postable: BasicPost;
};

export function isRichPost(post: BasicPostable): post is BasicPostable {
  return (
    post.postableType === "basicPost" && !isEmpty(post.postable.tiptapJson)
  );
}
