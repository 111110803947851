import { z } from "zod";
import { zEmptyStrToUndefined } from "../../lib/zod-helpers.js";

export const reorderCategoriesZod = z.object({
  categories: z.array(
    z.object({
      id: z.string(),
      order: z.number(),
    }),
  ),
});

export type ReorderCategoriesInput = z.infer<typeof reorderCategoriesZod>;

export const reorderCategoryItemsZod = z.object({
  items: z.array(
    z.object({
      id: z.string(),
      order: z.number(),
    }),
  ),
});

export type ReorderCategoryItemsInput = z.infer<typeof reorderCategoryItemsZod>;

const itemZod = z.object({
  id: z.string().optional(),
  postableId: z.string(),
  postableType: z.string(),
  order: z.number().optional().default(0),
  postable: z.any(),
  deleted: z.boolean().optional().default(false),
  json: z.any().optional(),
});

export const categoryZod = z.object({
  title: z.string().default(""),
  order: z.number().optional(),
  displayType: z.enum(["stories", "vertical", "horizontal", "grid"]),
  backgroundColor: zEmptyStrToUndefined,
  postables: z.array(itemZod),
});

export type CategoryInput = z.infer<typeof categoryZod>;
