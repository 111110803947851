import FeedStoriesTable from "@/components/feed/feed-stories-table";
import { createFileRoute } from "@tanstack/react-router";

function FeedStories() {
  return <FeedStoriesTable />;
}

export const Route = createFileRoute("/_authenticated/app/stories")({
  component: FeedStories,
});
