import { TableHeader } from "@/components/web-links/table-header";
import WebLinksTable from "@/components/web-links/web-links-table";
import { createFileRoute } from "@tanstack/react-router";

function WebLinks() {
  return (
    <div>
      <TableHeader />
      <WebLinksTable />
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/web-links/")({
  component: () => <WebLinks />,
});
