import { EventsForm } from "@/components/events/events-form";
import { createFileRoute } from "@tanstack/react-router";

function NewEvent() {
  return <EventsForm isEdit={false} />;
}

export const Route = createFileRoute(
  "/_authenticated/calendar/$calendarId/events/new",
)({
  component: () => <NewEvent />,
});
