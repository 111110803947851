import { buildHeaders } from "@/api/axios";
import { env } from "@/utils/env";
import { Editor, EditorContent } from "@tiptap/react";
import { useCallback, useState } from "react";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Input } from "../ui/input";
import "./tipytap.css";

const MenuBar = ({ editor }: { editor: Editor | null }) => {
  const [isStreaming, setIsStreaming] = useState(false);
  const [isObjectStreaming, setIsObjectStreaming] = useState(false);
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [postPrompt, setPostPrompt] = useState("");

  const toggleLink = useCallback(() => {
    if (!editor) return;

    if (editor.isActive("link")) {
      editor.chain().focus().unsetLink().run();
    } else {
      editor.chain().focus().setLink({ href: "" }).run();
    }
  }, [editor]);

  if (!editor) {
    return null;
  }

  const addImage = () => {
    const url = window.prompt("Enter the image URL:");
    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  };

  const startStreaming = async () => {
    if (!editor) return;

    setIsStreaming(true);
    setIsGenerateModalOpen(false);

    const streamingParagraph = editor.schema.nodes.paragraph.create();
    const streamingTransaction = editor.state.tr.insert(
      editor.state.doc.content.size,
      streamingParagraph,
    );
    editor.view.dispatch(streamingTransaction);

    try {
      const response = await fetch(`${env.VITE_API_URL}/ai/generate/stream`, {
        method: "POST",
        headers: buildHeaders(),
        body: JSON.stringify({ prompt, currentContent: editor.getJSON() }),
      });
      const reader = response.body?.getReader();
      if (!reader) {
        throw new Error("Unable to get reader from response");
      }

      const decoder = new TextDecoder();
      let isReading = true;
      while (isReading) {
        const { done, value } = await reader.read();
        if (done) {
          isReading = false;
          break;
        }

        const chunk = decoder.decode(value);
        // Insert the chunk at the current cursor position
        editor.commands.insertContent(chunk);
      }
    } catch (error) {
      console.error("Streaming error:", error);
    } finally {
      setIsStreaming(false);
      setPrompt("");
    }
  };

  const generatePost = async () => {
    if (!editor) return;

    setIsObjectStreaming(true);
    setIsPostModalOpen(false);

    try {
      const response = await fetch(`${env.VITE_API_URL}/ai/generate/object`, {
        method: "POST",
        headers: buildHeaders(),
        body: JSON.stringify({ prompt: postPrompt }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate post");
      }

      const newContent = await response.json();
      editor.commands.clearContent();
      editor.commands.setContent(newContent.object.content);
    } catch (error) {
      console.error("Post generation error:", error);
    } finally {
      setIsObjectStreaming(false);
      setPostPrompt("");
    }
  };

  const openStreamingModal = () => {
    setIsGenerateModalOpen(true);
  };

  const handleStreamingSubmit = () => {
    setIsGenerateModalOpen(false);
    startStreaming();
  };

  const handlePostSubmit = () => {
    setIsPostModalOpen(false);
    generatePost();
  };

  return (
    <div className="flex flex-wrap gap-2 mb-4">
      <Button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        variant={editor.isActive("bold") ? "default" : "outline"}
      >
        Bold
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        variant={editor.isActive("italic") ? "default" : "outline"}
      >
        Italic
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        variant={editor.isActive("strike") ? "default" : "outline"}
      >
        Strike
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        variant={editor.isActive("code") ? "default" : "outline"}
      >
        Code
      </Button>
      <Button
        onClick={() => editor.chain().focus().unsetAllMarks().run()}
        variant="outline"
      >
        Clear marks
      </Button>
      <Button
        onClick={() => editor.chain().focus().clearNodes().run()}
        variant="outline"
      >
        Clear nodes
      </Button>
      <Button
        onClick={() => editor.chain().focus().setParagraph().run()}
        variant={editor.isActive("paragraph") ? "default" : "outline"}
      >
        Paragraph
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        variant={
          editor.isActive("heading", { level: 1 }) ? "default" : "outline"
        }
      >
        H1
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        variant={
          editor.isActive("heading", { level: 2 }) ? "default" : "outline"
        }
      >
        H2
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        variant={
          editor.isActive("heading", { level: 3 }) ? "default" : "outline"
        }
      >
        H3
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        variant={editor.isActive("bulletList") ? "default" : "outline"}
      >
        Bullet list
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        variant={editor.isActive("orderedList") ? "default" : "outline"}
      >
        Ordered list
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        variant={editor.isActive("codeBlock") ? "default" : "outline"}
      >
        Code block
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        variant={editor.isActive("blockquote") ? "default" : "outline"}
      >
        Blockquote
      </Button>
      <Button
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
        variant="outline"
      >
        Horizontal rule
      </Button>
      <Button
        onClick={() => editor.chain().focus().setHardBreak().run()}
        variant="outline"
      >
        Hard break
      </Button>
      <Button
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
        variant="outline"
      >
        Undo
      </Button>
      <Button
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
        variant="outline"
      >
        Redo
      </Button>
      <Button
        onClick={() => editor.chain().focus().setColor("#958DF1").run()}
        variant={
          editor.isActive("textStyle", { color: "#958DF1" })
            ? "default"
            : "outline"
        }
      >
        Purple
      </Button>

      <Button onClick={addImage} variant="outline">
        Add Image
      </Button>

      <Button
        onClick={toggleLink}
        disabled={!editor.can().chain().focus().toggleLink({ href: "" }).run()}
        variant={editor.isActive("link") ? "default" : "outline"}
      >
        Link
      </Button>

      <Button
        onClick={openStreamingModal}
        disabled={isStreaming}
        variant="outline"
      >
        {isStreaming ? "Streaming..." : "AI Generate"}
      </Button>

      <Button
        onClick={() => setIsPostModalOpen(true)}
        disabled={isObjectStreaming}
        variant="outline"
      >
        {isObjectStreaming ? "Loading..." : "AI Post"}
      </Button>

      <Dialog
        open={isPostModalOpen}
        onOpenChange={(open) => setIsPostModalOpen(open)}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Tell AI to Write Your Post</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            This will fully replace the current post with an AI generated post
            based on your prompt. You can use this to quickly generate a new
            post or to get some ideas for your next post.
          </DialogDescription>
          <Input
            value={postPrompt}
            onChange={(e) => setPostPrompt(e.target.value)}
            placeholder="Enter your prompt here..."
          />
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsPostModalOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handlePostSubmit} disabled={isStreaming}>
              {isStreaming ? "Streaming..." : "Submit"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isGenerateModalOpen} onOpenChange={setIsGenerateModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Tell AI What to Write for You</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            This will use AI to insert content into the editor on your current
            cursor position. Provide a prompt to guide the AI on what to write.
          </DialogDescription>
          <Input
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            placeholder="Enter your prompt here..."
          />
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsGenerateModalOpen(false)}
            >
              Cancel
            </Button>
            <Button onClick={handleStreamingSubmit} disabled={isStreaming}>
              {isStreaming ? "Streaming..." : "Submit"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export function TipyTap({ tiptapEditor }: { tiptapEditor: Editor | null }) {
  const editor = tiptapEditor;

  return (
    <div className="max-w-4xl mx-auto mt-8">
      <MenuBar editor={editor} />

      <div className="bg-white shadow-lg rounded-lg p-4">
        <EditorContent editor={editor} />
      </div>
    </div>
  );
}
