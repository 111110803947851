import { PostsForm } from "@/components/posts/posts-form";
import { createFileRoute } from "@tanstack/react-router";

function NewPost() {
  return <PostsForm isEdit={false} />;
}

export const Route = createFileRoute("/_authenticated/posts/new")({
  component: () => <NewPost />,
});
