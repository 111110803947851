import { Conversations } from "@/components/conversations/conversations";
import { createFileRoute } from "@tanstack/react-router";

function ConversationPage() {
  const { conversationId } = Route.useParams<{ conversationId: string }>();
  return <Conversations initialSelectedConversationId={conversationId} />;
}

export const Route = createFileRoute(
  "/_authenticated/conversations/$conversationId",
)({
  component: () => <ConversationPage />,
});
