import IncidentsTable from "@/components/incidents/incidents-table";
// import { TableHeader } from "@/components/table/table-header";
import { createFileRoute } from "@tanstack/react-router";

function Incidents() {
  return (
    <div>
      {/* <TableHeader to="/incidents/new" title="Add Incident" /> */}
      <IncidentsTable />
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/incidents/")({
  component: Incidents,
});
