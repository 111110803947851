import { resetPassword, resetUserPassword } from "@/api/auth.api";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useAuth } from "@/hooks/useAuth";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Icons } from "../ui/icons";

const resetPasswordZodSchema = z
  .object({
    password: z.string().min(8, "Password is required"),
    confirmPassword: z.string().min(8),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export type ResetPasswordForm = z.infer<typeof resetPasswordZodSchema>;

export function ResetPasswordForm() {
  const search = useSearch({
    from: "/reset-password",
  });

  const {
    resetToken = "",
    authType = "u",
    orgId = "",
  } = search as { resetToken?: string; authType?: "a" | "u"; orgId?: string };

  const { login } = useAuth();
  const navigate = useNavigate();

  const form = useForm({
    resolver: zodResolver(resetPasswordZodSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const {
    mutate: resetAudiencePasswordMutation,
    isPending: isAudienceLoading,
  } = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      navigate({
        to: "/reset-success",
      });
    },
  });

  const { mutate: resetUserPasswordMutation, isPending: isUserLoading } =
    useMutation({
      mutationFn: resetUserPassword,
      onSuccess: (data) => {
        login(data.jwt, data.claims);
        navigate({
          to: "/residents",
        });
      },
    });

  function onSubmit(values: ResetPasswordForm) {
    if (authType === "a") {
      resetAudiencePasswordMutation({
        newPassword: values.password,
        resetToken,
        orgId,
      });
    } else {
      resetUserPasswordMutation({
        newPassword: values.password,
        resetToken,
        orgId,
      });
    }
  }

  const isLoading = isAudienceLoading || isUserLoading;

  return (
    <div className="grid gap-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid gap-2">
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>New Password</FormLabel>
                  <FormControl>
                    <Input type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm Password</FormLabel>
                  <FormControl>
                    <Input type="password" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button disabled={isLoading} className="mt-2">
              {isLoading && (
                <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
              )}
              Reset Password
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
