import { TipyTap } from "@/components/tipytap/tipytap";
import { tiptapExtensions } from "@/components/tipytap/tipytap-helpers";
import { createFileRoute } from "@tanstack/react-router";
import { useEditor } from "@tiptap/react";

function TipTap() {
  const editor = useEditor({
    extensions: tiptapExtensions,
    content: `
      <h1>Welcome to the Post builder</h1>
      <p>This is a demonstration of the various formatting options available in this editor.</p>

      <h2>Basic Formatting</h2>
      <p>You can make text <strong>bold</strong>, <em>italic</em>, or <u>underlined</u>. You can also <s>strike through</s> text.</p>
 
      <h2>Images</h2>
      <p>You can insert images into your content:</p>
      <img src="https://placehold.co/600x400?text=Sample+Image" alt="Sample Image" />

      <h2>Quotes</h2>
      <blockquote>
        "This is an example of a blockquote. Use it to highlight important information or quotes."
      </blockquote>

      <h2>Lists</h2>
      <p>Create ordered and unordered lists:</p>
      <ul>
        <li>Unordered item 1</li>
        <li>Unordered item 2</li>
      </ul>
      <ol>
        <li>Ordered item 1</li>
        <li>Ordered item 2</li>
      </ol>

      <h2>Advanced Formatting</h2>
      <p>Experiment with different heading levels, text colors, and more using the toolbar above.</p>
      
      <hr />
      <p><em>Use this editor to create engaging content.</em></p>
    `,
  });

  return <TipyTap tiptapEditor={editor} />;
}

export const Route = createFileRoute("/_authenticated/posts/tipy-tap")({
  component: () => <TipTap />,
});
