import { WebLinksForm } from "@/components/web-links/web-links-form";
import { createFileRoute } from "@tanstack/react-router";

function NewWebLink() {
  return <WebLinksForm isEdit={false} />;
}

export const Route = createFileRoute("/_authenticated/web-links/new")({
  component: () => <NewWebLink />,
});
