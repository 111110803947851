import { eventQueryOptions } from "@/api/events.api";
import { EventsForm } from "@/components/events/events-form";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const routeParamsSchema = z.object({
  eventId: z.string(),
});

function EditEvent() {
  const eventId = Route.useParams().eventId;
  const eventQuery = useSuspenseQuery(eventQueryOptions(eventId));
  const event = eventQuery.data;

  return <EventsForm initialValues={event} isEdit />;
}

export const Route = createFileRoute(
  "/_authenticated/calendar/$calendarId/events/$eventId",
)({
  component: () => <EditEvent />,
  // @ts-expect-error - TODO: fix this
  parseParams: (params) => routeParamsSchema.parse(params),
  loader: ({ context: { queryClient }, params }) =>
    queryClient.ensureQueryData(eventQueryOptions(params.eventId)),
});
