/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { presignedUrl, uploadToPresignedUrl } from "@/api/storage.api";
import { UseFormReturn } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Upload } from "lucide-react";
import { Icons } from "../ui/icons";

interface ImageInputProps {
  form: UseFormReturn<any>;
  field: any;
  contentType: string;
  displayType?: "card";
}

const CardDisplay: React.FC<{
  succeededUrl: string | null;
  fileInputRef: React.RefObject<HTMLInputElement>;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  setSucceededUrl: React.Dispatch<React.SetStateAction<string | null>>;
  form: UseFormReturn<any>;
  field: any;
  isUploading: boolean;
}> = ({
  succeededUrl,
  fileInputRef,
  setFile,
  setSucceededUrl,
  form,
  field,
  isUploading,
}) => {
  return (
    <div className="grid gap-2">
      {succeededUrl ? (
        <div>
          <img
            src={succeededUrl}
            alt="Uploaded"
            className="aspect-square w-full rounded-md object-cover"
            style={{ maxWidth: "300px", maxHeight: "300px" }}
          />
          <Button
            variant={"outline"}
            onClick={(e) => {
              e.preventDefault();
              setFile(null);
              setSucceededUrl(null);
              form.resetField(field.name);
            }}
          >
            Remove
          </Button>
        </div>
      ) : (
        <button
          className="flex aspect-square w-full items-center justify-center rounded-md border border-dashed"
          onClick={(e) => {
            e.preventDefault();
            fileInputRef.current?.click();
          }}
        >
          {!isUploading ? (
            <Upload className="h-4 w-4 text-muted-foreground" />
          ) : null}
          {isUploading ? (
            <Icons.spinner className="h-4 w-4 animate-spin" />
          ) : null}
          <span className="sr-only">Upload</span>
        </button>
      )}
    </div>
  );
};

const DefaultDisplay: React.FC<{
  succeededUrl: string | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  setSucceededUrl: React.Dispatch<React.SetStateAction<string | null>>;
  form: UseFormReturn<any>;
  field: any;
  isUploading: boolean;
}> = ({ succeededUrl, setFile, setSucceededUrl, form, field, isUploading }) => {
  return (
    <div className="grid grid-cols-2 gap-4 mt-2">
      {isUploading ? <Icons.spinner className="h-4 w-4 animate-spin" /> : null}
      {succeededUrl ? (
        <div>
          <img
            src={succeededUrl}
            alt="Uploaded"
            style={{ maxWidth: "100px", maxHeight: "100px" }}
          />
        </div>
      ) : null}
      {succeededUrl ? (
        <Button
          variant={"outline"}
          onClick={(e) => {
            e.preventDefault();
            setFile(null);
            setSucceededUrl(null);
            form.resetField(field.name);
          }}
        >
          Remove
        </Button>
      ) : null}
    </div>
  );
};

export const ImageInput: React.FC<ImageInputProps> = ({
  form,
  field,
  contentType,
  displayType,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const { mutateAsync: getPresignedUrl } = useMutation({
    mutationFn: presignedUrl,
  });

  const [succeededUrl, setSucceededUrl] = useState<string | null>(
    form.getValues(field.name),
  );

  const { mutateAsync: uploadFile } = useMutation({
    mutationFn: (args: { url: string; file: File }) =>
      uploadToPresignedUrl(args.url, args.file),
  });

  useEffect(() => {
    if (file) {
      setIsUploading(true);
      const specificContentType = file.type || contentType;

      getPresignedUrl({
        contentType: specificContentType,
        extension: file.name.split(".").pop() || "",
        persist: true,
      })
        .then((data) => {
          if (data?.presigned) {
            uploadFile({ url: data.presigned, file }).then(() => {
              const urlWithoutQuery = data.presigned.split("?")[0];
              setSucceededUrl(urlWithoutQuery);
              form.setValue(field.name, urlWithoutQuery);
            });
          }
        })
        .finally(() => {
          setIsUploading(false);
        });
    }
  }, [file, contentType, getPresignedUrl, uploadFile, form, field.name]);

  return (
    <div>
      {displayType === "card" ? (
        <CardDisplay
          succeededUrl={succeededUrl}
          fileInputRef={fileInputRef}
          setFile={setFile}
          setSucceededUrl={setSucceededUrl}
          form={form}
          field={field}
          isUploading={isUploading}
        />
      ) : (
        <DefaultDisplay
          succeededUrl={succeededUrl}
          setFile={setFile}
          setSucceededUrl={setSucceededUrl}
          form={form}
          field={field}
          isUploading={isUploading}
        />
      )}

      <Input
        className={displayType === "card" ? "hidden" : ""}
        ref={fileInputRef}
        type="file"
        hidden={displayType === "card"}
        accept={contentType || "image/*"}
        onChange={(e) => {
          const files = e.target.files;
          if (files && files[0]) {
            setFile(files[0]);
          }
        }}
      />
    </div>
  );
};
