import React, { useState, ChangeEvent } from "react";
import { AsYouType } from "libphonenumber-js";
import { Input } from "./input";

interface PhoneInputProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export function PhoneInput({
  value,
  onChange,
  onBlur,
  ...rest
}: PhoneInputProps) {
  const [rawValue, setRawValue] = useState("");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    if (text.length < value.length) {
      // If backspacing, update rawValue by removing the last character
      const newRawValue = rawValue.slice(0, -1);
      const formatter = new AsYouType("US");
      const formatted = formatter.input(newRawValue);
      setRawValue(newRawValue);
      onChange({
        target: { value: formatted },
      } as ChangeEvent<HTMLInputElement>);
    } else {
      // Remove all non-digit characters
      const digitsOnly = text.replace(/\D/g, "");
      // Create a new AsYouType instance for each input
      const formatter = new AsYouType("US");
      const formatted = formatter.input(digitsOnly);
      setRawValue(digitsOnly);
      onChange({
        target: { value: formatted },
      } as ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <Input
      type="tel"
      {...rest}
      onChange={handleChange}
      onBlur={onBlur}
      value={value}
      maxLength={14}
    />
  );
}
