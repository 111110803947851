import { sectionQueryOptions } from "@/api/quick-access.api";
import { SectionForm } from "@/components/quick-access-sections/section-form";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const parseParams = z.object({
  sectionId: z.string(),
});

function EditSection() {
  const sectionId = Route.useParams().sectionId;
  const sectionQuery = useSuspenseQuery(sectionQueryOptions(sectionId));
  const section = sectionQuery.data;

  return <SectionForm isEdit initialValues={section} />;
}

export const Route = createFileRoute(
  "/_authenticated/app/quick-access/sections/$sectionId",
)({
  component: EditSection,
  parseParams: (params) => parseParams.parse(params),
  loader: ({ context: { queryClient }, params }) =>
    queryClient.ensureQueryData(sectionQueryOptions(params.sectionId)),
});
