import ResidentsTable from "@/components/residents/residents-table";
import { TableHeader } from "@/components/residents/table-header";
import { createFileRoute } from "@tanstack/react-router";

function Residents() {
  return (
    <div>
      <TableHeader />
      <ResidentsTable />
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/residents/")({
  component: () => <Residents />,
});
