import { queryOptions } from "@tanstack/react-query";
import { axios, buildHeaders } from "./axios";
import { Organization } from "zora-api/prisma/generated";
import { OrganizationFormData } from "zora-api/concerns/organizations/organizations.zod";

export const organizationsQueryOptions = queryOptions({
  queryKey: ["organizations"],
  queryFn: () => fetchOrganizations(),
});

export const organizationQueryOptions = (organizationId: string) => ({
  queryKey: ["organization", organizationId],
  queryFn: () => fetchOrganization(organizationId),
});

export const myOrganizationsQueryOptions = queryOptions({
  queryKey: ["myOrganizations"],
  queryFn: () => fetchMyOrganizations(),
});

export const fetchMyOrganizations = async (): Promise<Organization[]> => {
  return axios
    .get<Organization[]>("/organizations/my", {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const fetchOrganizations = async (): Promise<Organization[]> => {
  return axios
    .get<Organization[]>("/super-admin/organizations", {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const fetchOrganization = async (
  organizationId: string,
): Promise<Organization> => {
  return axios
    .get<Organization>(`/super-admin/organizations/${organizationId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const updateOrganization = async ({
  id: organizationId,
  ...organization
}: Partial<Organization>): Promise<Organization> => {
  return axios
    .put<Organization>(
      `/super-admin/organizations/${organizationId}`,
      organization,
      {
        headers: buildHeaders(),
      },
    )
    .then((r) => r.data);
};

export const createOrganization = async (
  organization: OrganizationFormData,
): Promise<Organization> => {
  return axios
    .post<Organization>("/super-admin/organizations", organization, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const deleteOrganization = async (
  organizationId: string,
): Promise<void> => {
  return axios
    .delete(`/super-admin/organizations/${organizationId}`, {
      headers: buildHeaders(),
    })
    .then(() => undefined);
};
