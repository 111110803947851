import { z } from "zod";
import { organizationZodForm } from "../organizations/organizations.zod.js"; // Corrected import

export const mediaZod = z.object({
  organizationId: z.string().cuid(),
  cloudStorageObjectId: z.string().cuid(),
  title: z.string().min(1),
  description: z.string().min(1),
});

export type MediaFormData = z.infer<typeof mediaZod>;
