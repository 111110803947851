import EventsTable from "@/components/events/events-table";
import { TableHeader } from "@/components/events/table-header";
import { createFileRoute } from "@tanstack/react-router";

function Events() {
  return (
    <div>
      <TableHeader />
      <EventsTable />
    </div>
  );
}

export const Route = createFileRoute(
  "/_authenticated/calendar/$calendarId/events/",
)({
  component: () => <Events />,
});
