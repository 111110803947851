import { SectionForm } from "@/components/quick-access-sections/section-form";
import { createFileRoute } from "@tanstack/react-router";

function NewSection() {
  return <SectionForm isEdit={false} />;
}

export const Route = createFileRoute(
  "/_authenticated/app/quick-access/sections/new",
)({
  component: NewSection,
});
