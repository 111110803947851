import { z } from "zod";
import { zStringOrDate } from "../../lib/zod-helpers.js";

export const postableFilterTypesSchema = z
  .array(
    z.enum([
      "basicPost",
      "event",
      "contactCard",
      "video",
      "webLink",
      "place",
      "nativeScreen",
    ]),
  )
  .optional();

export type PostableFilterTypes = z.infer<typeof postableFilterTypesSchema>;

export const postableTypes = z.enum([
  "basicPost",
  "event",
  "contactCard",
  "video",
  "webLink",
  "place",
  "nativeScreen",
]);

export type PostableTypes = z.infer<typeof postableTypes>;

export const postableZod = z.discriminatedUnion("postableType", [
  z.object({
    id: z.string().optional(),
    organizationId: z.string(),
    postableType: z.literal("basicPost"),
    postableId: z.string(),
    postable: z.object({
      id: z.string(),
      organizationId: z.string(),
      title: z.string(),
      body: z.string(),
      image: z.string().nullable(),
      tiptapJson: z.any().nullable(),
    }),
  }),
  z.object({
    id: z.string().optional(),
    organizationId: z.string(),
    postableType: z.literal("event"),
    postableId: z.string(),
    postable: z.object({
      title: z.string(),
      start: zStringOrDate,
      end: zStringOrDate,
      allDay: z.boolean(),
      description: z.string(),
      imageUrl: z.string(),
      calendarId: z.string(),
    }), // Add event-specific fields here
  }),
  z.object({
    id: z.string().optional(),
    organizationId: z.string(),
    postableType: z.literal("contactCard"),
    postableId: z.string(),
    postable: z.object({}), // Add contactCard-specific fields here
  }),
  z.object({
    id: z.string().optional(),
    organizationId: z.string(),
    postableType: z.literal("video"),
    postableId: z.string(),
    postable: z.object({}), // Add video-specific fields here
  }),
  z.object({
    id: z.string().optional(),
    organizationId: z.string(),
    postableType: z.literal("webLink"),
    postableId: z.string(),
    postable: z.object({}), // Add webLink-specific fields here
  }),
  z.object({
    id: z.string().optional(),
    organizationId: z.string(),
    postableType: z.literal("place"),
    postableId: z.string(),
    postable: z.object({}), // Add place-specific fields here
  }),
  z.object({
    id: z.string().optional(),
    organizationId: z.string(),
    postableType: z.literal("nativeScreen"),
    postableId: z.string(),
    postable: z.object({}), // Add nativeScreen-specific fields here
  }),
]);

export type ZodPostable = z.infer<typeof postableZod>;

export const postableFilters = z.object({
  types: postableFilterTypesSchema,
});

export type PostableFilters = z.infer<typeof postableFilters>;

export const nativeScreenJsonSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  route: z.string(),
  json: z.object({}),
  organizationId: z.string().optional(),
});

export const gridPostableJsonSchema = z.object({
  backgroundColor: z.string(),
  title: z.string(),
  icon: z.string(),
  iconColor: z.string().optional(),
});

export type GridPostableJson = z.infer<typeof gridPostableJsonSchema>;
