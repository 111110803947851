import { createFileRoute } from "@tanstack/react-router";
// import { Map3DExample } from "@/components/map/map3d/app";
// import { APIProvider } from "@vis.gl/react-google-maps";

function Map3D() {
  // the world isn't ready
  return (
    <div>
      <h1>Map3D</h1>
    </div>
    // <APIProvider
    //   apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
    //   version={"alpha"}
    // >
    //   <div className="h-screen flex">
    //     <Map3DExample />
    //   </div>
    // </APIProvider>
  );
}

export const Route = createFileRoute("/_authenticated/residents/3dmap")({
  component: () => <Map3D />,
});
