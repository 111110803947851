import { z } from "zod";

export const loginZodForm = z.object({
  email: z
    .string()
    .email({ message: "Invalid email address." })
    .transform((v) => v.toLowerCase().trim()),
  password: z
    .string()
    .min(8, { message: "Password must be at least 8 characters long" }),
});

export type LoginInput = z.infer<typeof loginZodForm>;

export const baseSignUpZodForm = z.object({
  email: z.string().email({ message: "Invalid email address." }),
  password: z
    .string()
    .min(8, { message: "Password must be at least 8 characters long" }),
});

export const signUpZodForm = baseSignUpZodForm
  .extend({
    confirmPassword: z.string(),
  })
  .transform((data) => ({
    ...data,
    email: data.email.toLowerCase().trim(),
  }))
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

export type SignUpInput = z.infer<typeof signUpZodForm>;

export const profileZodForm = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  streetAddress: z.string().min(1, { message: "Street address is required" }),
  phoneNumber: z.string().min(10, { message: "Invalid phone number" }),
  zip: z.string().length(5, { message: "Invalid zip code" }),
});

export type ProfileInput = z.infer<typeof profileZodForm>;

export const signUpResponseZod = z.object({
  jwt: z.string(),
  audienceId: z.string(),
});

export type SignUpResponse = z.infer<typeof signUpResponseZod>;

export const signUpRequestInput = baseSignUpZodForm.merge(profileZodForm);

export type SignUpRequestInput = z.infer<typeof signUpRequestInput>;

export const forgotPasswordZod = z.object({
  email: z.string().email({ message: "Invalid email address." }),
});

export type ForgotPasswordInput = z.infer<typeof forgotPasswordZod>;

export const resetPasswordZod = z.object({
  newPassword: z.string().min(8),
  resetToken: z.string(),
});

export type ResetPasswordInput = z.infer<typeof resetPasswordZod>;
