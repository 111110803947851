import { usersQueryOptions } from "@/api/users.api";
import { TableHeader } from "@/components/tables/add-header";
import { DropdownItem } from "@/components/tables/data-row";
import { DataTable } from "@/components/tables/data-table";
import { UserModalForm } from "@/components/users/users-modal-form";
import { prettyFormatDateWithTime } from "@/lib/utils";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { ColumnDef } from "@tanstack/react-table";
import { useState } from "react";
import { User } from "zora-api/prisma/generated";
import { ConfirmModal } from "@/components/ui/confirm-modal"; // New import
import { deleteUser } from "@/api/users.api"; // New import
import { useMutation, useQueryClient } from "@tanstack/react-query"; // New imports

const columns: ColumnDef<User>[] = [
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorKey: "createdAt",
    header: "Created At",
    cell: ({ row }) => {
      return <div>{prettyFormatDateWithTime(row.original.createdAt)}</div>;
    },
  },
];

function Users() {
  const usersQuery = useSuspenseQuery(usersQueryOptions);
  const users = usersQuery.data;
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Add state for deleting a user
  const [deleteUserId, setDeleteUserId] = useState<string | null>(null); // New state
  const queryClient = useQueryClient(); // New hook

  // Mutation hook for deleting a user
  const { mutate: deleteUserMutation, isPending: isDeleting } = useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
      setDeleteUserId(null);
    },
  }); // New mutation hook

  // Update dropdown items to include Delete option
  const dropdownItems: DropdownItem<User>[] = [
    {
      label: "Delete",
      onClick: ({ id }) => {
        setDeleteUserId(id);
      },
    },
  ];

  return (
    <div>
      <TableHeader onClick={() => setIsModalOpen(true)} buttonText="Add User" />
      <DataTable
        data={users}
        columns={columns}
        title="Users"
        description="Manage your users"
        dropdownItems={dropdownItems} // Updated dropdownItems
      />
      <UserModalForm
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <ConfirmModal
        open={Boolean(deleteUserId)}
        onConfirm={() => {
          if (deleteUserId) deleteUserMutation(deleteUserId);
        }}
        onDismiss={() => {
          setDeleteUserId(null);
        }}
        isLoading={isDeleting}
      />{" "}
      {/* New ConfirmModal */}
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/settings/users")({
  component: () => <Users />,
});
