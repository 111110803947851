"use client";

import { Checkbox } from "@/components/ui/checkbox";
import { ColumnDef } from "@tanstack/react-table";
import { Story } from "zora-api/concerns/mobile/mobile.router";
import { DataTableRowActions } from "./stories.actions";
import { DataTableColumnHeader } from "./stories.header";

export const columns: ColumnDef<Story>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "fullTitle",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Full Title" />
    ),
    cell: ({ row }) => <div>{row.getValue("fullTitle")}</div>,
    enableSorting: true,
    enableHiding: true,
    filterFn: (row, _id, value) => {
      return row.original.fullTitle.toLowerCase().includes(value.toLowerCase());
    },
  },
  {
    accessorKey: "shortTitle",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Short Title" />
    ),
    cell: ({ row }) => {
      const shortTitle = row.original.shortTitle;

      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {shortTitle}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "url",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Url" />
    ),
    cell: ({ row }) => {
      const url = row.original.url;

      return (
        <div className="flex items-center">
          <span className="max-w-[200px] truncate font-medium">{url}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "image",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Image" />
    ),
    cell: ({ row }) => {
      const image = row.original.image;

      return (
        <div className="flex items-center">
          <span className="max-w-[200px] truncate font-medium">{image}</span>
        </div>
      );
    },
  },
  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
];
