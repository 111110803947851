import { type ClassValue, clsx } from "clsx";
import dayjs from "dayjs";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function titleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
  );
}

export function randomElement<T>(array: Array<T>): T {
  return array[Math.floor(Math.random() * array.length)];
}

export function formatDateForPicker(date: Date | string | undefined) {
  return dayjs(date || new Date()).format("YYYY-MM-DDTHH:mm");
}

export function prettyFormatDate(date: string | Date) {
  return dayjs(date).format("MM/DD/YYYY");
}

export function prettyFormatDateWithTime(date: string | Date) {
  return dayjs(date).format("MM/DD/YYYY h:mm A");
}

export function isEpoch(date: string | Date) {
  return dayjs(date).isSame(dayjs(0));
}

export function friendlyPostableType(type: string) {
  if (type === "basicPost") {
    return "Post";
  }
  if (type === "video") {
    return "Video";
  }

  if (type === "nativeScreen") {
    return "Native Screen";
  }

  return type
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function friendlyPostableTitle(postable: any) {
  return (
    postable?.title ||
    postable?.name ||
    postable?.fullTitle ||
    postable?.postableId
  );
}
