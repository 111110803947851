import { z } from "zod";
import dayjs from "../../lib/dayjs.js";

const baseEventZod = z.object({
  title: z.string(),
  description: z.string(),
  imageUrl: z.string(),
  allDay: z.boolean(),
});

export const eventZodForm = baseEventZod.extend({
  start: z.string().datetime(),
  end: z.string().datetime(),
});

export type EventInput = z.infer<typeof eventZodForm>;

export const eventCreateZod = baseEventZod.extend({
  start: z.preprocess((val: any) => dayjs(val).toDate(), z.date()),
  end: z.preprocess((val: any) => dayjs(val).toDate(), z.date()),
});
