import { mediaItemQueryOptions } from "@/api/media.api";
import { MediaForm } from "@/components/media/media-form";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

const routeParamsSchema = z.object({
  mediaId: z.string(),
});

function EditMedia() {
  const mediaId = Route.useParams().mediaId;
  const mediaQuery = useSuspenseQuery(mediaItemQueryOptions(mediaId));
  const media = mediaQuery.data;

  return <MediaForm isEdit={true} initialValues={media} />;
}

export const Route = createFileRoute("/_authenticated/media/$mediaId")({
  component: () => <EditMedia />,
  parseParams: (params) => routeParamsSchema.parse(params),
  loader: ({ context: { queryClient }, params }) =>
    queryClient.ensureQueryData(mediaItemQueryOptions(params.mediaId)),
});
