import { authKey, orgKey } from "@/hooks/useAuth";
import { env } from "@/utils/env";
import axiosBase from "axios";

export function buildHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem(authKey)}`,
    "x-org-id": localStorage.getItem(orgKey) || "",
  };
}

export const axios = axiosBase.create({
  baseURL: env.VITE_API_URL,
  headers: buildHeaders(),
});
