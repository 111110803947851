// import { createFileRoute, useNavigate } from "@tanstack/react-router";
// import {
//   Dialog,
//   DialogContent,
//   DialogDescription,
//   DialogFooter,
//   DialogHeader,
//   DialogTitle,
// } from "@/components/ui/dialog";
// import { z } from "zod";
// import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
// import { getLink, updateTranslations } from "@/api/links.api";
// import {
//   Form,
//   FormControl,
//   FormDescription,
//   FormField,
//   FormItem,
//   FormLabel,
//   FormMessage,
// } from "@/components/ui/form";
// import { Input } from "@/components/ui/input";
// import { useForm } from "react-hook-form";
// // import {
// //   QuickLinksTranslationsSchema,
// //   quickLinksTranslationsZodSchema,
// // } from "zora-api/concerns/quick-links/quick-links.zod";
// import { zodResolver } from "@hookform/resolvers/zod";
// import { Button } from "@/components/ui/button";
// import { Icons } from "@/components/ui/icons";
// import { useState } from "react";

import { createFileRoute } from "@tanstack/react-router";

// const translationsSchema = z.object({
//   linkId: z.string(),
// });

// function Translations() {
//   const [isModalOpen, setIsModalOpen] = useState(true);
//   const { linkId } = Route.useSearch();
//   const { data: link } = useQuery({
//     queryKey: ["links", linkId],
//     queryFn: () => getLink(linkId),
//   });
//   const navigate = useNavigate();
//   const queryClient = useQueryClient();
//   // const form = useForm<QuickLinksTranslationsSchema>({
//   //   resolver: zodResolver(quickLinksTranslationsZodSchema),
//   //   defaultValues: {
//   //     es: {
//   //       // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   //       title: (link?.translations as any)?.es?.title,
//   //     },
//   //   },
//   // });

//   const {
//     // mutate: updateTranslationsMutation,
//     isPending: isLoading,
//     error: submitError,
//   } = useMutation({
//     mutationFn: updateTranslations,
//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: ["links"] });
//       setIsModalOpen(false);
//       navigate({ to: "/links" });
//     },
//   });

//   // const onSubmit = (data: QuickLinksTranslationsSchema) => {
//   //   updateTranslationsMutation({ linkId, ...data });
//   // };

//   return (
//     <Dialog
//       open={isModalOpen}
//       onOpenChange={(open) => {
//         setIsModalOpen(open);
//         if (!open) {
//           navigate({ to: "/links" });
//         }
//       }}
//     >
//       <DialogContent className="sm:max-w-[700px]">
//         <DialogHeader>
//           <DialogTitle>Quick Link Translations</DialogTitle>
//           <DialogDescription>
//             Manage Spanish translations for your quick link.
//           </DialogDescription>
//         </DialogHeader>

//         <Form {...form}>
//           <form onSubmit={form.handleSubmit(onSubmit)}>
//             <div className="grid grid-cols-2 gap-4 py-4">
//               <FormField
//                 control={form.control}
//                 name="es.title"
//                 render={({ field }) => (
//                   <FormItem>
//                     <FormLabel>Spanish Title</FormLabel>
//                     <FormControl>
//                       <Input placeholder="Spanish Title" {...field} />
//                     </FormControl>
//                     <FormDescription>
//                       The Spanish title of your link.
//                     </FormDescription>
//                     <FormMessage />
//                   </FormItem>
//                 )}
//               />
//               <FormItem>
//                 <FormLabel>English Title</FormLabel>
//                 <FormControl>
//                   <Input
//                     placeholder="English Title"
//                     defaultValue={link?.title}
//                     readOnly
//                   />
//                 </FormControl>
//                 <FormDescription>
//                   The current English title of your link. This cannot be edited
//                   here.
//                 </FormDescription>
//               </FormItem>
//             </div>
//             <DialogFooter>
//               {submitError && <FormMessage>{submitError.message}</FormMessage>}
//               <Button type="submit">
//                 {isLoading && (
//                   <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
//                 )}
//                 Save
//               </Button>
//             </DialogFooter>
//           </form>
//         </Form>
//       </DialogContent>
//     </Dialog>
//   );
// }

function Translations() {
  return <h1>Translations</h1>;
}

export const Route = createFileRoute("/_authenticated/links/translations")({
  component: Translations,
  // validateSearch: translationsSchema,
});
