import SectionsTable from "@/components/quick-access-sections/sections-table";
import { TableHeader } from "@/components/quick-access-sections/table-header";
import { createFileRoute } from "@tanstack/react-router";

function QuickAccess() {
  return (
    <div>
      <TableHeader />
      <SectionsTable />
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/app/quick-access/")({
  component: QuickAccess,
});
