import { FacebookLogin } from "@/routes/_public/facebook/login";
import { createFileRoute } from "@tanstack/react-router";

function Connections() {
  return <FacebookLogin />;
}

export const Route = createFileRoute("/_authenticated/settings/connections")({
  component: Connections,
});
