import { z } from "zod";

const rawEnvVariables = z.object({
  VITE_API_URL: z.string().url().default("https://api.zora.cloud"),
});

const zodParsed = rawEnvVariables.safeParse(import.meta.env);

if (!zodParsed.success) {
  throw new Error(
    `env vars missing or invalid. Look in .env.sample and copy to .env ${zodParsed.error.message}`,
  );
}

export const env = zodParsed.data;
export const isProd = import.meta.env.MODE === "production";
