/* eslint-disable @typescript-eslint/no-explicit-any */
import { deletePost, postsQueryOptions } from "@/api/posts.api";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { MoreHorizontal } from "lucide-react";
import { useState } from "react";
import { Post } from "zora-api/concerns/mobile/mobile.router";
import { ConfirmModal } from "../ui/confirm-modal";

function PostRow({
  post,
  navigate,
  setDeletePostId,
}: {
  post: Post;
  navigate: any;
  setDeletePostId: (id: string | null) => void;
}) {
  return (
    <TableRow>
      <TableCell className="font-medium">{post.title}</TableCell>
      <TableCell>{post.body}</TableCell>
      <TableCell>
        {post.image ? (
          <img src={post.image} alt="thumbnail" className="w-6 h-6" />
        ) : null}
      </TableCell>
      <TableCell>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => {
                navigate({
                  to: `/posts/${post.id}`,
                });
              }}
            >
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setDeletePostId(post.id)}>
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  );
}

export default function PostsTable() {
  const postsQuery = useSuspenseQuery(postsQueryOptions);
  const posts = postsQuery.data;
  const [deletePostId, setDeletePostId] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { mutate: deletePostMutation, isPending: isLoading } = useMutation({
    mutationFn: deletePost,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      setDeletePostId(null);
    },
  });

  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader>
        <CardTitle>Posts</CardTitle>
        <CardDescription>
          Posts allow you to share updates and news with your users.
        </CardDescription>
      </CardHeader>
      <ConfirmModal
        open={Boolean(deletePostId)}
        onConfirm={() => {
          if (deletePostId) deletePostMutation(deletePostId);
        }}
        onDismiss={() => {
          setDeletePostId(null);
        }}
        isLoading={isLoading}
      />
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Title</TableHead>
              <TableHead>Body</TableHead>
              <TableHead>Image</TableHead>
              <TableHead>
                <span className="sr-only">Actions</span>
              </TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {posts.map((post) => (
              <PostRow
                key={post.id}
                post={post}
                navigate={navigate}
                setDeletePostId={setDeletePostId}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <div className="text-xs text-muted-foreground">
          Showing <strong>1-{posts.length}</strong> of{" "}
          <strong>{posts.length}</strong> posts
        </div>
      </CardFooter>
    </Card>
  );
}
