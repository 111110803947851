"use client";

import { allPostables } from "@/api/postables.api";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { usePostablesCommandSearch } from "@/hooks/usePostablesCommandSearch";
import { friendlyPostableTitle, friendlyPostableType } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import { Postable } from "zora-api/concerns/postables/postables.router";
import { PostableFilters } from "zora-api/concerns/postables/postables.zod";

export function PostablesCommandSeach({
  onItemSelect,
  existingIds,
  filters,
}: {
  onItemSelect: (item: Postable) => void;
  existingIds?: string[];
  filters?: PostableFilters;
}) {
  const filterIds = existingIds || [];
  const open = usePostablesCommandSearch((state) => state.isOpen);
  const setOpen = usePostablesCommandSearch((state) => state.setIsOpen);
  const { data, isFetching, isLoading } = useQuery({
    queryKey: ["all-postables"],
    queryFn: () => {
      return allPostables(filters);
    },
    staleTime: 0,
  });

  if (isFetching || isLoading) {
    return <div>Loading...</div>;
  }
  if (data === undefined) return <div>Loading...</div>;

  return (
    <>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Search..." />
        <CommandList>
          <CommandEmpty>No results found.</CommandEmpty>
          <CommandGroup heading="Postables">
            {data
              .filter((f) => !filterIds.includes(f.postableId))
              .map((postable) => (
                <CommandItem
                  onSelect={() => {
                    onItemSelect(postable);
                  }}
                  key={postable.postableId}
                >{`${friendlyPostableType(
                  postable.postableType,
                )}: ${friendlyPostableTitle(postable.postable)}`}</CommandItem>
              ))}
          </CommandGroup>
          {/* <CommandGroup heading="Suggestions">
            <CommandItem>
              <CalendarIcon className="mr-2 h-4 w-4" />
              <span>Calendar</span>
            </CommandItem>
            <CommandItem>
              <FaceIcon className="mr-2 h-4 w-4" />
              <span>Search Emoji</span>
            </CommandItem>
            <CommandItem>
              <RocketIcon className="mr-2 h-4 w-4" />
              <span>Launch</span>
            </CommandItem>
          </CommandGroup>
          <CommandSeparator />
          <CommandGroup heading="Settings">
            <CommandItem>
              <PersonIcon className="mr-2 h-4 w-4" />
              <span>Profile</span>
              <CommandShortcut>⌘P</CommandShortcut>
            </CommandItem>
            <CommandItem>
              <EnvelopeClosedIcon className="mr-2 h-4 w-4" />
              <span>Mail</span>
              <CommandShortcut>⌘B</CommandShortcut>
            </CommandItem>
            <CommandItem>
              <GearIcon className="mr-2 h-4 w-4" />
              <span>Settings</span>
              <CommandShortcut>⌘S</CommandShortcut>
            </CommandItem>
          </CommandGroup> */}
        </CommandList>
      </CommandDialog>
    </>
  );
}
