import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { ChevronLeft } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import {
  createOrganization,
  updateOrganization,
} from "@/api/organizations.api";

const organizationZodForm = z.object({
  name: z
    .string()
    .min(1, "Organization name is required")
    .max(255, "Organization name cannot exceed 255 characters"),
  parentId: z.string().cuid().nullable().optional(),
  city: z.string().default("unknown"),
  state: z.string().default("unknown"),
});

type OrganizationFormData = z.infer<typeof organizationZodForm>;

type OrganizationFormProps =
  | {
      isEdit: true;
      initialValues: OrganizationFormData & { id: string };
    }
  | {
      isEdit: false;
      initialValues?: Partial<OrganizationFormData>;
    };

export function OrganizationForm({
  isEdit,
  initialValues,
}: OrganizationFormProps) {
  const router = useRouter();
  const navigate = useNavigate();
  const organizationId = isEdit ? initialValues.id : undefined;
  const queryClient = useQueryClient();

  const form = useForm<OrganizationFormData>({
    resolver: zodResolver(organizationZodForm),
    defaultValues: {
      name: initialValues?.name || "",
      parentId: initialValues?.parentId || null,
      city: initialValues?.city || "unknown",
      state: initialValues?.state || "unknown",
    },
  });

  const { mutate: createOrganizationMutation } = useMutation({
    mutationFn: (organization: OrganizationFormData) =>
      createOrganization(organization),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["organizations"] });
      navigate({ to: "/super-admin/organizations" });
    },
  });

  const { mutate: updateOrganizationMutation } = useMutation({
    mutationFn: (organization: OrganizationFormData & { id: string }) =>
      updateOrganization(organization),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["organizations"] });
      if (organizationId)
        queryClient.invalidateQueries({
          queryKey: ["organization", organizationId],
        });
      // @ts-expect-error - TODO: fix this
      navigate({ to: "/organizations" });
    },
  });

  const onSubmit = (data: OrganizationFormData) => {
    if (isEdit && organizationId) {
      updateOrganizationMutation({
        ...data,
        id: organizationId,
      });
    } else {
      createOrganizationMutation(data);
    }
  };

  return (
    <div className="mx-auto grid max-w-[59rem] flex-1 auto-rows-max gap-4 mt-4">
      <Form {...form}>
        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={() => {
              router.history.back();
            }}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Back</span>
          </Button>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            {isEdit ? "Edit Organization" : "New Organization"}
          </h1>
          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => {
                router.history.back();
              }}
            >
              Discard
            </Button>
            <Button size="sm" onClick={form.handleSubmit(onSubmit)}>
              Save Organization
            </Button>
          </div>
        </div>

        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <Card>
              <CardHeader>
                <CardTitle>Organization Details</CardTitle>
                <CardDescription>
                  Enter the details for your organization.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Organization Name</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="parentId"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Parent Organization ID (optional)</FormLabel>
                        <FormControl>
                          <Input {...field} value={field.value || ""} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="grid gap-4 sm:grid-cols-2">
                    <FormField
                      control={form.control}
                      name="city"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>City</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="state"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>State</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
            {/* You can add additional cards or content for the right column here */}
          </div>
        </div>

        <div className="flex items-center justify-center gap-2 md:hidden mt-4">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              router.history.back();
            }}
          >
            Discard
          </Button>
          <Button size="sm" onClick={form.handleSubmit(onSubmit)}>
            Save Organization
          </Button>
        </div>
      </Form>
    </div>
  );
}
