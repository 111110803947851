import { OrganizationForm } from "@/components/organizations/organizations-form";
import { createFileRoute } from "@tanstack/react-router";

function NewOrganization() {
  return <OrganizationForm isEdit={false} />;
}

export const Route = createFileRoute(
  "/_authenticated/super-admin/organizations/new",
)({
  component: () => <NewOrganization />,
});
